import { SPoint } from './SPoint'
import { SLine } from './SLine'
import { GeomDataHolder } from './GeomDataHolder'
import { Layer } from './Layer'
import { SketchLayer } from './SketchLayer'
import { GeometryObserver, GeometryObserverEvents } from './GeometryObserver'

import '../ThirdParty/lines/LineSegmentsGeometry'
import '../ThirdParty/lines/LineGeometry'
import '../ThirdParty/lines/LineSegments2'
import '../ThirdParty/lines/LineMaterial'
// import '../ThirdParty/lines/Wireframe'
// import '../ThirdParty/lines/WireframeGeometry2'
import '../ThirdParty/lines/Line2'

ScalablePlugin.version = '1.0.1'
ScalablePlugin.GEOM_SELECT = 'onGeomSelect'
ScalablePlugin.GEOM_DESELECT = 'onGeomDeSelect'
ScalablePlugin.GEOM_CLICK = 'onGeomClick'

ScalablePlugin.prototype = Object.create(AnkaPanAPI.PanoPlugin.prototype)
ScalablePlugin.prototype.constructor = ScalablePlugin

ScalablePlugin._instances = []
function ScalablePlugin (ops) {
    AnkaPanAPI.PanoPlugin.apply(this, arguments)
    ScalablePlugin._instances.push(this)
    this._roll = 0
    this._heading = 0
    this._pitch = 0
    this._tempraryPolygons = []
    this._points = []
    this._lines = []
    this._layers = []
    this.ClassInstance = ScalablePlugin
    this.initialOptions = ops

    if (ops) {
        for (var str in ops) {
            this['_' + str] = ops[str]
        }
    }

    GeometryObserver.addEvent(GeometryObserverEvents.NEW_GEOMETRIES, this, this._onGeomAddedToPool)
    GeometryObserver.addEvent(GeometryObserverEvents.GEOMETRY_REMOVE, this, this._onGeomRemoved)
}

ScalablePlugin.prototype._onGeomAddedToPool = function (event) {
    let gdhs = event.geometries
    for (let i = 0; i < gdhs.length; i++) {
        const gdh = gdhs[i]
        if (gdh.isCompleted()) {
            this.addGDH(gdh)
        } else if (gdh.isDrawing()) {
            this.addGDH(gdh)
            gdh.addEvent(GeomDataHolder.CHANGE, this, this._onChangeGeomDataHolder)
        }
    }
}

ScalablePlugin.prototype._onGeomRemoved = function (event) {
    let target = event.target
    if (target.layer instanceof Layer) {
        target.layer.removeFromGlobal(target)
        target.layer.redraw()
    } else {
        console.warn("Geomtry doesn't have a defined layer")
    }
}

ScalablePlugin.prototype._onChangeGeomDataHolder = function (e) {
    let gdh = e.target
    let layer = gdh.layer
    if (gdh.isCompleted()) {
        gdh.removeEvent(GeomDataHolder.CHANGE, this._onChangeGeomDataHolder)
    }

    layer.redraw()
}

ScalablePlugin.prototype.addGDH = function (gdh) {
    let layer = gdh.layer || this.getMainSketchLayer()
    gdh.layer = layer
    layer.addToList(gdh)
}

ScalablePlugin.prototype.getMainSketchLayer = function () {
    var sketchLayer = this.__sketchLayer
    if (!sketchLayer) {
        sketchLayer = new SketchLayer('sketchLayer', '__uniqueID')
        sketchLayer.setScalable(this)
        sketchLayer.setMouseEnable(true)
        sketchLayer.isSketchLayer = true
        sketchLayer.redraw(true)
        this.__sketchLayer = sketchLayer
    }
    return sketchLayer
}

ScalablePlugin.prototype.prepare = function () {
    var t = this
    this.baseObject.getScalable = function () {
        return t
    }

    t.scalableScene = new THREE.Scene()
    var tloader = new THREE.TextureLoader()
    var texture = tloader.load('img/grid.png', function (data) { t.setDirty() })
    var plgns = this.baseObject.getPlugins()

    for (var i = 0; i < plgns.length; i++) {
        let plugin = plgns[i]
        if (plugin instanceof AnkaSoftText.SoftTextPlugin) {
            this.softText = plgns[i]
            break
        }
    }

    if (!this.softText) {
        console.log('SoftTextPlugin bulunamadı. label sahne üzerinde gösterilemeyebilir.')
    }

    texture.generateMipmaps = false
    texture.magFilter = THREE.LinearFilter
    texture.minFilter = THREE.LinearFilter
    var material = new THREE.MeshBasicMaterial({ map: texture, transparent: true, opacity: 0.9 })
    var geometry = new THREE.SphereBufferGeometry(1001, 64, 64)
    t.gridSphere = new THREE.Mesh(geometry, material)
    t.gridSphere.scale.set(-1, 1, 1)

    var lineTexture = tloader.load('img/straight.png', function (data) {
        t.setDirty()
    })

    lineTexture.generateMipmaps = false
    lineTexture.magFilter = THREE.NearestFilter
    lineTexture.minFilter = THREE.NearestFilter
    var geometry2 = new THREE.SphereBufferGeometry(1000, 64, 64)
    var material2 = new THREE.MeshBasicMaterial({ map: lineTexture, transparent: true, opacity: 0.6 })
    t.lineScene = new THREE.Mesh(geometry2, material2)
    t.lineScene.scale.set(-1, 1, 1)
    this.softRenderCounter = 3
    this.createPlane()
    var p = AnkaPanAPI.PanoGL
    t.baseObject.addEvent(p.DATA_COMPLETE, t, t.onDataComplate)
    // t.baseObject.addEvent(p.THUMB_IMAGE_DISPLAYED, t, t.onThumbComplete)
    t.baseObject.addEvent(p.LOCATION_CHANGE, t, t.onThumbComplete)
    t.baseObject.addEvent(p.RENDER_AFTER_GROUND, t, t.renderAfterGround)

    if (!t.baseObject.initialized) {
        t.baseObject.addEvent(p.SETUP_COMLETE, this, function () {
            this.panoSetupCompleted()
        })
    } else {
        this.panoSetupCompleted()
    }
}

ScalablePlugin.prototype.addLayer = function (lyr) {
    if (lyr instanceof Layer) {
        this._layers.push(lyr)
        lyr.setScalable(this)
    } else {
        console.error('Object is not instance of AnkaScalable.Layer')
    }
}

ScalablePlugin.prototype.getLayers = function () {
    return this._layers
}

ScalablePlugin.prototype.removeLayer = function (lyr) {
    if (lyr instanceof Layer) {
        var index = this._layers.indexOf(lyr)
        if (index > -1) {
            const l = this._layers[index]
            l.removeAllChildren()
            this._layers.splice(index, 1)
            // l.setScalable(undefined)
        }
    } else {
        console.error('Object is not instance of AnkaScalable.Layer')
    }
}

ScalablePlugin.prototype.panoSetupCompleted = function () {
    var gm = this.baseObject.getGroundMaster()
    gm.addEvent(AnkaPanAPI.GroundMaster.CAMERA_ORIENTATION_CHANGE, this, this.onCameraOrientationChange)
    gm.addEvent(AnkaPanAPI.GroundMaster.CAMERA_HEIGHT_CHANGE, this, this.onCameraHeightChange)
}

ScalablePlugin.prototype.updateAliveGeom = function () {
    var ActiveHolder = ScalablePlugin.activeGeomDataHolder
    if (!this.activeGeom || ActiveHolder.uniqueID === this.activeGeom.uniqueID) {
        if (this.activeGeom) {
            this.activeGeom.destroy()
        }
        var TempClass = ActiveHolder.sampleClass
        this.activeGeom = new TempClass(this, ActiveHolder)
        this.activeGeom.enable(this.baseObject)
        this.activeGeom.setData(ActiveHolder.points, ActiveHolder.attributes)
        this.activeGeom.uniqueID = ActiveHolder.uniqueID
        this.activeGeom.setLayer(ActiveHolder.layer)
        this.scalableScene.add(this.activeGeom)
        this.activeGeom.updateStyle()
        ActiveHolder.addEvent(GeomDataHolder.CHANGE, this, this.onActiveGeomChanged)
    }
}

ScalablePlugin.prototype.destroyActiveGeometry = function () {
    if (this.activeGeom) {
        this.activeGeom.destroy()
    }

    var ah = ScalablePlugin.activeGeomDataHolder
    if (ah) {
        ah.removeEvent(GeomDataHolder.CHANGE, this.onActiveGeomChanged)
    }
    this.activeGeom = null
    ScalablePlugin.activeGeomDataHolder = null
}

ScalablePlugin.prototype.destroyActiveGeometryForAllInstance = function () {
    for (var i = 0; i < ScalablePlugin._instances.length; i++) {
        ScalablePlugin._instances[i].destroyActiveGeometry()
    }
}

ScalablePlugin.prototype.onActiveGeomChanged = function (e) {
    var ah = e.target

    if (ah.dynamicPoint) {
        this.activeGeom.setDynamicPoint(ah.dynamicPoint)
    }

    this.activeGeom.setData(ah.points, ah.attributes)
    this.activeGeom.update()

    if (ah.getStatus() === GeomDataHolder.STATUS.COMPLETED) {
        this.activeGeom.stopDraw()
        this.activeGeom.update()
        ah.addEvent(GeomDataHolder.CLICK, this, this.onGeomDataHolderClicked)
        ah.addEvent(GeomDataHolder.SELECT, this, this.onGeomDataHolderSelected)
        ah.addEvent(GeomDataHolder.DESELECT, this, this.onGeomDataHolderDeSelected)

        // TODO Sonradan eklendi
        ah.removeEventWithContext(GeomDataHolder.CHANGE, this, this.onActiveGeomChanged)
        this._tempraryPolygons.push(this.activeGeom)
    } else if (ah.getStatus() === GeomDataHolder.STATUS.BAD_COMPLETED) {
        if (this.activeGeom) {
            this.activeGeom.destroy()
            this.activeGeom = null
        }
        ah.removeEventWithContext(GeomDataHolder.CHANGE, this, this.onActiveGeomChanged)
    }
}

/**
* Disable all mouse operations in scenes. (like click, hover etc.)
*@method disableMouse
*/
ScalablePlugin.prototype.disableMouse = function () {
    this.scalableScene.mouseDisabled = true
}

/**
* Enables all mouse operations in scenes. (like click, hover etc.)
*@method enableMouse
*/
ScalablePlugin.prototype.enableMouse = function () {
    this.scalableScene.mouseDisabled = false
}

ScalablePlugin.prototype.getCurrentAltitude = function () {
    var cd = this.currentData.current
    var alt = cd.altitude - this.camHegInMet
    return alt
}

ScalablePlugin.prototype.mouseMoveOnBaseDiv = function () {
    this.baseObject.setSoftCanvasDirty()
    this.softRenderCounter = 4
}

ScalablePlugin.prototype.onCameraOrientationChange = function () {
    this.redrawGeometries()
}

ScalablePlugin.prototype.onCameraHeightChange = function (e) {
    this.camHegInMet = e.meter
    this.camHegInPix = e.pixel
    this.plane.position.set(0, -e.pixel, 0)
}

ScalablePlugin.prototype.redrawGeometries = function () {
    var p = this._points
    for (var i = 0; i < p.length; i++) {
        var pp = this.calculatePointPositionFromLonLatAlt(p[i].lon, p[i].lat, p[i].alt)
        p[i].m.position.set(pp.x, pp.y, pp.z)
        p[i].m.reset()
    }
}

ScalablePlugin.prototype.addPoint = function (lon, lat, alt, dontAddList) {
    var m = new SPoint(this)
    m.enable(this.baseObject)
    this.scalableScene.add(m)
    m.setLocation(lon, lat, alt)
    m.reset()

    if (!dontAddList) this._points.push(m)

    return m
}

ScalablePlugin.prototype.addLine = function (geomText) {
    var gt = geomText.replace('MULTILINESTRING ZM ', '').replace('((', '').replace('))', '')
    var points = gt.split(',')

    var line = []
    for (var i = 0; i < points.length; i++) {
        let l = points[i].split(' ')
        var pos = { lon: l[0], lat: l[1], alt: l[2] }
        line.push(pos)
    }

    let l = new SLine(this)
    l.enable(this.baseObject)
    l.setPoints(line)
    this.scalableScene.add(l)
    this._lines.push(l)
}

ScalablePlugin.prototype.selectGeometry = function (e) {
    if (!this.selectionMat) {
        this.selectionMat = new THREE.MeshBasicMaterial({ color: 0x00ffff, depthWrite: false, depthTest: false, transparent: true, opacity: 0.6 })
    }

    var target = e.target
    var targetParent = e.parentTarget
    var geom = target.geometry
    var m = new THREE.Mesh(geom, this.selectionMat)
    this.selected = target
    this.scalableScene.updateMatrixWorld()
    m.position.setFromMatrixPosition(target.matrixWorld)
    m.scale.setFromMatrixScale(target.matrixWorld)
    m.rotation.setFromRotationMatrix(target.matrixWorld)

    m.scale.multiplyScalar(1.1)

    var l = new THREE.Geometry()
    l.vertices.push(
        new THREE.Vector3(targetParent.position.x, targetParent.position.y, targetParent.position.z),
        new THREE.Vector3(0, 0.2, 0)
    )

    var line = new THREE.Line(l, this.selectionMat)
    this.scalableScene.add(m)
    this.scalableScene.add(line)
}

ScalablePlugin.prototype.calculatePointPositionFromLonLatAlt = function (lon, lat, alt) {
    if (!this.currentData || !this.currentData.current) {
        alt = alt === undefined || isNaN(alt) ? 0 : alt
        return { x: lon, y: lat, z: alt }
    }

    alt = alt === undefined || isNaN(alt) ? this.getCurrentAltitude() : alt
    var Utils = AnkaPanAPI.Utils
    var cd = this.currentData.current

    var azimuth = ((Utils.bearing(cd.lat, cd.lon, lat, lon))) % 360
    azimuth = (360 - azimuth) % 360

    var theta = azimuth
    var dist = Utils.heversine(cd.lat, lat, cd.lon, lon)
    var hip = (dist * Math.abs(this.camHegInPix)) / this.camHegInMet
    var rad = (360 - (theta + 90)) / 180 * Math.PI

    var altPx = ((alt - cd.altitude) * Math.abs(this.camHegInPix)) / this.camHegInMet

    var xx = hip * Math.cos(rad)
    var zz = hip * Math.sin(rad)
    var yy = altPx
    return { x: xx, y: yy, z: zz }
}

/**
 * Convert lon, lat, alt to cartesian coordinates.
 * highly depending on current panoramic data which means, It will give cartesian coordinates according to current panoramic point.
 * no current point will return "null"
 * @param { float } lon
 * @param { float } lat
 * @param { float } alt
 * @return { {x,y,z} }
 */
ScalablePlugin.prototype.fromLonLatAlt = function (lon, lat, alt) {
    if (!this.currentData || !this.currentData.current) {
        return null
    }

    alt = alt === undefined || isNaN(alt) ? this.getCurrentAltitude() : alt
    var Utils = AnkaPanAPI.Utils
    var cd = this.currentData.current

    var azimuth = ((Utils.bearing(cd.lat, cd.lon, lat, lon))) % 360
    azimuth = (360 - azimuth) % 360

    var theta = azimuth
    var dist = Utils.heversine(cd.lat, lat, cd.lon, lon)
    var hip = (dist * Math.abs(this.camHegInPix)) / this.camHegInMet
    var rad = (360 - (theta + 90)) / 180 * Math.PI

    var altPx = ((alt - cd.altitude) * Math.abs(this.camHegInPix)) / this.camHegInMet

    var xx = hip * Math.cos(rad)
    var zz = hip * Math.sin(rad)
    var yy = altPx
    return { x: xx, y: yy, z: zz }
}

/**
 * Convert lon, lat, alt to cartesian coordinates.
 * highly depending on current panoramic data which means, It will give cartesian coordinates according to current panoramic point.
 * no current point will return "null"
 * @param { float } x
 * @param { float } y
 * @param { float } z
 * @return { {lon,lat,alt} }
 */
ScalablePlugin.prototype.toLonLatAlt = function (x, y, z) {
    let basePano = this.baseObject
    let groundMaster = basePano.groundMaster
    return groundMaster.posToLoc({ x, y, z })
}

ScalablePlugin.loc2Pos = function (cd, lon, lat, alt, chInPixel, camHegInMet) {
    alt = alt === undefined ? cd.altitude : alt
    var Utils = AnkaPanAPI.Utils

    var azimuth = ((Utils.bearing(cd.lat, cd.lon, lat, lon))) % 360
    azimuth = (360 - azimuth) % 360

    var theta = azimuth
    var dist = Utils.heversine(cd.lat, lat, cd.lon, lon)

    var hip = (dist * Math.abs(chInPixel)) / camHegInMet
    var rad = (360 - (theta + 90)) / 180 * Math.PI

    var altPx = ((alt - cd.altitude) * Math.abs(chInPixel)) / camHegInMet

    var xx = hip * Math.cos(rad)
    var zz = hip * Math.sin(rad)
    var yy = altPx
    return { x: xx, y: yy, z: zz }
}

ScalablePlugin.prototype.createPlane = function () {
    var tloader = new THREE.TextureLoader()
    var t = this
    var gridText = tloader.load('img/grid_t.png', function (data) {
        t.setDirty()
    })

    var fpMat = new THREE.MeshBasicMaterial({ opacity: 0.5, transparent: true, map: gridText })
    gridText.wrapS = gridText.wrapT = THREE.RepeatWrapping
    gridText.repeat.set(50, 50)
    gridText.minFilter = THREE.LinearFilter

    var geom = new THREE.Geometry()
    var sx = 1000
    var v1 = new THREE.Vector3(-sx, 0, -sx)
    var v2 = new THREE.Vector3(sx, 0, -sx)
    var v3 = new THREE.Vector3(sx, 0, sx)
    var v4 = new THREE.Vector3(-sx, 0, sx)

    geom.vertices.push(v1)
    geom.vertices.push(v2)
    geom.vertices.push(v3)
    geom.vertices.push(v4)

    geom.faces.push(new THREE.Face3(2, 1, 0))
    geom.faces.push(new THREE.Face3(0, 3, 2))

    var fc1UV = [new THREE.Vector2(1, 1), new THREE.Vector2(1, 0), new THREE.Vector2(0, 0)]
    var fc2UV = [new THREE.Vector2(0, 0), new THREE.Vector2(0, 1), new THREE.Vector2(1, 1)]

    geom.faceVertexUvs[0] = []

    geom.faceVertexUvs[0].push(fc1UV)
    geom.faceVertexUvs[0].push(fc2UV)

    this.plane = new THREE.Mesh(geom, fpMat)
    this.plane.position.set(0, -100, 0)
}

ScalablePlugin.prototype.renderAfterGround = function (e) {
    var bo = this.baseObject
    var renderer = bo.getRenderer()
    var cam = bo.getMainCamera()

    renderer.clearDepth()
    renderer.render(this.scalableScene, cam)
}

ScalablePlugin.prototype.getStandingPoint = function () {
    return this._standingLatlon
}

ScalablePlugin.prototype.getBasePano = function () {
    return this.baseObject
}

ScalablePlugin.prototype.onDataComplate = function (e) {
    var d = e.data
    this.setCameraHeight(d.rawData.config.camheight)
    this.currentData = d
    this._standingLatlon = d.current
}

ScalablePlugin.prototype.refresh = function (isForce) {
    this.redrawLayers(isForce)
    this.updateSavedGeoms(isForce)
}

ScalablePlugin.prototype.redrawLayers = function (isForce) {
    for (var i = 0; i < this._layers.length; i++) {
        this._layers[i].redraw(isForce)
    }

    var sketchLayer = this.getMainSketchLayer()
    sketchLayer.redraw(isForce)
    this.setDirty()
}

ScalablePlugin.prototype.updateSavedGeoms = function (isForce) {
    this.setDirty()
}

ScalablePlugin.prototype.updateSavedGeomsSyncron = function () {
    for (var i = 0; i < ScalablePlugin._instances.length; i++) {
        ScalablePlugin._instances[i].updateSavedGeoms()
    }
}

ScalablePlugin.prototype.onGeomDataHolderClicked = function (e) {
    this.throwEvent({ type: ScalablePlugin.GEOM_CLICK, target: e.target })
}

ScalablePlugin.prototype.onGeomDataHolderSelected = function (e) {
    var fitGeom
    for (var i = 0; i < this._tempraryPolygons.length; i++) {
        if (this._tempraryPolygons[i].uniqueID === e.target.uniqueID) {
            fitGeom = this._tempraryPolygons[i]
            fitGeom.onSelect()
            break
        }
    }

    this.throwEvent({ type: ScalablePlugin.GEOM_SELECT, target: e.target })
    this.baseObject.setDirty()
}

ScalablePlugin.prototype.onGeomDataHolderDeSelected = function (e) {
    var fitGeom
    for (var i = 0; i < this._tempraryPolygons.length; i++) {
        if (this._tempraryPolygons[i].uniqueID === e.target.uniqueID) {
            fitGeom = this._tempraryPolygons[i]
            fitGeom.onDeSelect()
            break
        }
    }

    this.throwEvent({ type: ScalablePlugin.GEOM_DESELECT, target: e.target })
    this.baseObject.setDirty()
}

ScalablePlugin.prototype.onThumbComplete = function (e) {
    this.refresh()
    this.updateOrientation()
}

ScalablePlugin.prototype.updateOrientation = function (e) {
    if (this.currentData) {
        var current = this.currentData.current
        var config = this.currentData.rawData.config
        var roll = current.roll
        var pitch = current.pitch
        var heading = current.heading

        if (config) {
            roll -= config.roll !== undefined ? config.roll : 0
            pitch -= config.pitch !== undefined ? config.pitch : 0
            heading -= config.heading !== undefined ? config.heading : 0
        }

        var headingRad = heading / 180 * Math.PI
        this.plane.rotation.set(0, -(headingRad - Math.PI), 0)
        this.gridSphere.rotation.set(0, -(headingRad + (Math.PI * 0.5)), 0, 'YXZ')

        roll -= this._roll
        pitch -= this._pitch
        heading -= this._heading
        var baseObject = this.baseObject
        // TODO
        baseObject.setOriantation(heading, pitch, roll)
    } else {
        console.warn('data yüklenmemiş.')
    }
}

ScalablePlugin.prototype.setOriantation = function (heading, pitch, roll) {
    this._heading = heading
    this._pitch = pitch
    this._roll = roll
    this.setDirty()
    this.updateOrientation()
}

ScalablePlugin.prototype.setCameraHeight = function (heighInMeter) {
    var gm = this.baseObject.getGroundMaster()
    gm.setCameraHeight(heighInMeter)
    this.setDirty()
}

ScalablePlugin.prototype.getCameraHeight = function () {
    return this.camHegInMet
}

ScalablePlugin.prototype.getRoll = function () {
    return this._roll
}

ScalablePlugin.prototype.getHeading = function () {
    return this._heading
}

ScalablePlugin.prototype.getPitch = function () {
    return this._pitch
}

ScalablePlugin.prototype.setHeading = function (h) {
    this._heading = h
    this.setOriantation(this._heading, this._pitch, this._roll)
}

ScalablePlugin.prototype.setRoll = function (r) {
    this._roll = r
    this.setOriantation(this._heading, this._pitch, this._roll)
}

ScalablePlugin.prototype.setPitch = function (p) {
    this._pitch = p
    this.setOriantation(this._heading, this._pitch, this._roll)
}

ScalablePlugin.prototype.openControls = function () {
    if (window.dat === undefined || window.dat.GUI === undefined) {
        var script = document.createElement('script')
        script.onload = this.openControls.bind(this)
        script.src = 'libs/dat.gui.min.js'
        document.head.appendChild(script) // or something of the likes
    } else {
        var gui = new dat.GUI()

        var gDom = gui.domElement
        gDom.style.position = 'absolute'
        gDom.style.top = '0px'
        gDom.style.left = '0px'
        gDom.style.zIndex = 9999

        var panogl = this.baseObject
        panogl.contentDV.appendChild(gDom)
        var t = this
        var items = {
            get 'Heading' () { return t.getHeading() },
            set 'Heading' (v) {
                t.setHeading(v)
            },
            get 'Roll' () { return t.getRoll() },
            set 'Roll' (v) { t.setRoll(v) },
            get 'Pitch' () { return t.getPitch() },
            set 'Pitch' (v) { t.setPitch(v) },
            get 'Camera Height' () { return t.getCameraHeight() },
            set 'Camera Height' (v) { t.setCameraHeight(v) }
        }

        var items2 = {}
        items2.Export = function () {
            var a = document.createElement('a')

            var data = '{' +
                '"roll":' + t.getRoll() + ',' +
                '"pitch":' + t.getPitch() + ',' +
                '"cameraSize":' + t.getCameraHeight() + ',' +
                '"heading":' + t.getHeading() + '' +
                '}'

            a.href = 'data:text/plain;base64,' + window.btoa(data)
            a.textContent = 'download'
            a.download = 'setup.ank'
            a.click()
        }

        items2.Destroy = function () {
            gui.destroy()
        }

        gui.add(items, 'Heading', -360, 360).step(0.01)
        gui.add(items, 'Roll', -90, 90).step(0.01)
        gui.add(items, 'Pitch', -90, 90).step(0.01)
        gui.add(items, 'Camera Height', 0, 5).step(0.03)

        gui.add(items2, 'Export')
        gui.add(items2, 'Destroy')
        gui.open()
    }

    return gDom
}

ScalablePlugin.wktToGeomData = function (wkt, attributes) {
    var Gdh = GeomDataHolder
    var typeClass = ScalablePlugin.getTypeClass(wkt)
    var geomObject = ScalablePlugin.getPoints(wkt)
    var points = geomObject.geoms
    var geomDataHolder = new Gdh(typeClass, points, attributes)
    geomDataHolder.isMultiGeom = geomObject.geomCount > 1
    geomDataHolder.holes = geomObject.holes
    geomDataHolder.setStatus(Gdh.STATUS.COMPLETED)
    return geomDataHolder
}

ScalablePlugin.addWKT = function (wkt) {
    var geomDataHolder = ScalablePlugin.wktToGeomData(wkt)
    var oneInstance = AnkaScalable.ScalablePlugin._instances[0]
    var layer = oneInstance.getMainSketchLayer()
    var geom = layer.addToList(geomDataHolder)
    layer.drawGeometry(geomDataHolder)
    oneInstance.setDirty()
    return geom
}

ScalablePlugin.addGDH = function (gdh) {
    GeometryObserver.addGeometries([gdh])
}

ScalablePlugin.getPoints = function (wkt) {
    if (wkt) {
        if (wkt.indexOf('MULTI') > -1) {
            var replaceType = /([A-Z])\w+/g
            var insidePar = /\(|\)/g

            if (wkt.indexOf('LINE') > -1) {
                let totalLines = []
                let newWKT = wkt.replace(replaceType, '').trim()
                let lines = newWKT.split('),(')

                for (let i = 0; i < lines.length; i++) {
                    let rawSTR = lines[i].trim()
                    let lineSTR = rawSTR.replace(insidePar, '')
                    let lineAr = lineSTR.split(',')
                    let line = []
                    for (let j = 0; j < lineAr.length; j++) {
                        let point = lineAr[j].split(' ')
                        let pt = { lon: parseFloat(point[0]), lat: parseFloat(point[1]), alt: parseFloat(point[2]) }
                        line.push(pt)
                    }

                    totalLines.push(line)
                }
                return { geomCount: totalLines.length, geoms: totalLines }
            } else if (wkt.indexOf('POLYGON') > -1) {
                let totalPol = []
                let newWKT = wkt.replace(replaceType, '').trim()
                let polygons = newWKT.split(')),((')

                for (let i = 0; i < polygons.length; i++) {
                    let polySTR = polygons[i]
                    let pointSTR = polySTR.replace(insidePar, '').trim()
                    let points = pointSTR.split(',')
                    let pts = []

                    for (let j = 0; j < points.length; j++) {
                        let p = points[j].trim()
                        let ps = p.split(' ')
                        pts.push({ lon: parseFloat(ps[0]), lat: parseFloat(ps[1]), alt: parseFloat(ps[2]) })
                    }
                    totalPol.push(pts)
                }

                return { geomCount: 1, geoms: totalPol }
            }
        } else {
            let replaceType = /([A-Z])\w+/g
            let replacePar = /\(|\)/g
            let newWKT = wkt.replace(replaceType, '')
            if (wkt.indexOf('POINT') > -1) {
                let pointSTR = newWKT.replace(replacePar, '').trim()
                let points = pointSTR.split(' ')

                let ar = [{ lon: parseFloat(points[0]), lat: parseFloat(points[1]), alt: parseFloat(points[2]) }]
                return { geomCount: 1, geoms: ar }
            } else if (wkt.indexOf('LINE') > -1) {
                let pointSTR = newWKT.replace(replacePar, '').trim()
                let points = pointSTR.split(',')

                let pts = []
                for (let i = 0; i < points.length; i++) {
                    let p = points[i].trim()
                    let ps = p.split(' ')
                    pts.push({ lon: parseFloat(ps[0]), lat: parseFloat(ps[1]), alt: parseFloat(ps[2]) })
                }
                return { geomCount: 1, geoms: pts }
            } else if (wkt.indexOf('POLYGON') > -1) {
                let geomAndHole = newWKT.split('),(')
                if (geomAndHole.length > 1) {
                    let pointSTR = geomAndHole[0].replace(replacePar, '').trim()
                    let points = pointSTR.split(',')
                    let pts = []
                    for (let i = 0; i < points.length; i++) {
                        let p = points[i].trim()
                        let ps = p.split(' ')
                        pts.push({ lon: parseFloat(ps[0]), lat: parseFloat(ps[1]), alt: parseFloat(ps[2]) })
                    }

                    let allHoles = []
                    for (let i = 1; i < geomAndHole.length; i++) {
                        let holesSTR = geomAndHole[i].replace(replacePar, '').trim()
                        let holePoints = holesSTR.split(',')
                        let holes = []
                        for (let j = 0; j < holePoints.length; j++) {
                            let p = holePoints[j].trim()
                            let ps = p.split(' ')
                            holes.push({ lon: parseFloat(ps[0]), lat: parseFloat(ps[1]), alt: parseFloat(ps[2]) })
                        }
                        allHoles.push(holes)
                    }

                    return { geomCount: 1, geoms: pts, holes: allHoles }
                } else {
                    let pointSTR = newWKT.replace(replacePar, '').trim()
                    let points = pointSTR.split(',')
                    let pts = []
                    for (let i = 0; i < points.length; i++) {
                        let p = points[i].trim()
                        let ps = p.split(' ')
                        pts.push({ lon: parseFloat(ps[0]), lat: parseFloat(ps[1]), alt: parseFloat(ps[2]) })
                    }
                    return { geomCount: 1, geoms: pts }
                }
            }
        }
    }
}

ScalablePlugin.prototype.destroy = function () {
    for (var i = 0; i < this._layers.length; i++) {
        this._layers[i].destroy()
    }
}

ScalablePlugin.getTypeClass = function (wkt) {
    if (wkt) {
        if (wkt.indexOf('MULTI') > -1) {
            if (wkt.indexOf('LINE') > -1) {
                return AnkaScalable.SMultiLine
            } else if (wkt.indexOf('POLYGON') > -1) {
                return AnkaScalable.SMultiPolygon
            }
        } else {
            if (wkt.indexOf('POINT') > -1) {
                return AnkaScalable.SPoint
            } else if (wkt.indexOf('LINE') > -1) {
                return AnkaScalable.SLine
            } else if (wkt.indexOf('POLYGON') > -1) {
                return AnkaScalable.SPolygon
            }
        }
    }
}

export { ScalablePlugin }
