
import { SGeom } from './SGeom'
import { EventMesh } from './EventMesh'

SMultiPolygon.isMultiGeom = true
function SMultiPolygon (scalable, geomDataHolder) {
    SGeom.apply(this, arguments)
    this.polygons = []
    this.fillMeshes = []
    this.lineMeshes = []
    this.triangles = []
    this.type = 'Polygon'
    this._lineColor = 0x0000ff
    this.canDraw = true
    this.scalable = scalable
    this.softText = scalable.softText
    this.opacity = 0.5
    this.labelHook = new THREE.Object3D()
    this.lineMaterial = new THREE.LineBasicMaterial({
        color: this._lineColor,
        linewidth: 1,
        depthTest: true,
        transparent: true,
        side: THREE.DoubleSide
    })

    this._fillColor = 0xffff00
    this.fillMat = new THREE.MeshBasicMaterial({
        color: this._fillColor,
        depthTest: true,
        transparent: true,
        opacity: this.opacity,
        side: THREE.DoubleSide
    })
}

SMultiPolygon.prototype = Object.assign(Object.create(SGeom.prototype),
    {
        constructor: SMultiPolygon,

        reset: function () {
            this.lookAt(new THREE.Vector3(0, this.position.y, 0))
        },

        enable: function (panogl, renderScene) {
            if (!this.enabled) {
                this.openGeom = true
                this.panogl = panogl
            }
        },

        stopDraw: function () {
            this.openGeom = false

            for (var i = 0; i < this.polygons.length; i++) {
                var points = this.polygons[i]
                var firstP = points[0]
                var lastIndex = points.length - 1
                var lastPoint = points[lastIndex]

                if (lastPoint.lat !== firstP.lat || lastPoint.lon !== firstP.lon || lastPoint.alt !== firstP.alt) {
                    points.push(firstP)
                }

                this.dynamicPoint = null
                this.update()
            }
        },

        setData: function (points, atts, holes) {
            this.attributes = atts
            this.polygons.length = 0
            for (var i = 0; i < points.length; i++) {
                var polygon = []
                var pts = points[i]
                for (var j = 0; j < pts.length; j++) {
                    polygon.push(pts[j])
                }
                this.polygons.push(polygon)
            }
        },

        calculateArea: function (triangles) {
            if (!triangles) {
                return
            }

            let Utils = AnkaPanAPI.Utils
            var totalS = 0
            for (var i = 0; i < triangles.length; i++) {
                var tri = triangles[i]
                var points = tri.getPoints()
                var a = points[0]
                var b = points[1]
                var c = points[2]

                var aalt = isNaN(a.alt) || a.alt === undefined || a.alt == null ? 0 : a.alt
                var balt = isNaN(b.alt) || b.alt === undefined || b.alt == null ? 0 : b.alt
                var calt = isNaN(c.alt) || c.alt === undefined || c.alt == null ? 0 : c.alt

                var AB = Utils.haversine(a.lat, b.lat, a.lon, b.lon)
                var ABC = Math.sqrt((AB * AB) + Math.pow(aalt - balt, 2))

                var BC = Utils.haversine(b.lat, c.lat, b.lon, c.lon)
                var BCD = Math.sqrt((BC * BC) + Math.pow(balt - calt, 2))

                var CA = Utils.haversine(c.lat, a.lat, c.lon, a.lon)
                var CAD = Math.sqrt((CA * CA) + Math.pow(calt - aalt, 2))

                var S = Utils.heron(ABC, BCD, CAD)
                totalS += S
            }

            return totalS
        },

        setLabel: function () {
            if (!this.openGeom && this.softText) {
                var c = this.getCentroid()
                var cam = this.panogl.getMainCamera()
                this.labelHook.position.set(c.x, c.y, c.z)
                this.add(this.labelHook)
                this._commonGeom.attributes['_areaSize'] = this.areaSize
                this.softText.addObject3D(this.labelHook, cam, this.getLabelText())
            }
        },
        getCentroid: function () {
            var sp = []

            for (let i = 0; i < this.polygons.length; i++) {
                let p = this.polygons[i]
                sp = sp.concat(p)
            }

            var minX = 500000000
            var minY = 500000000
            var minZ = 500000000
            var maxX = -500000000
            var maxY = -500000000
            var maxZ = -500000000

            for (let i = 0; i < sp.length; i++) {
                let p = sp[i]
                let pp = this.scalable.calculatePointPositionFromLonLatAlt(p.lon, p.lat, p.alt)

                if (pp.x < minX) { minX = pp.x }

                if (pp.x > maxX) { maxX = pp.x }

                if (pp.z < minZ) { minZ = pp.z }

                if (pp.z > maxZ) { maxZ = pp.z }

                if (pp.y < minY) { minY = pp.y }

                if (pp.y > maxY) { maxY = pp.y }
            }

            var x = minX + ((maxX - minX) / 2)
            var z = minZ + ((maxZ - minZ) / 2)
            var y = minY + ((maxY - minY) / 2)

            return { x, y, z }
        },
        onClick: function (e) {
            e.currentTarget = this
            this.throwEvent(e)
        },

        addPoint: function (lon, lat, alt) {
            console.warn('addpoint deprecated')
        },

        onSelect: function () {
            this.lineMaterial.color = new THREE.Color(0, 0.5, 1)
            this.fillMat.color = new THREE.Color(0.1, 1, 1)
            this.fillMat.opacity = 1
        },

        onDeSelect: function () {
            if (this.__layer) {
                this.updateStyle()
            } else {
                this.lineMaterial.color = new THREE.Color(this._lineColor)
                this.fillMat.color = new THREE.Color(this._fillColor)
                this.fillMat.opacity = this.opacity
            }
        },

        cleanMeshes: function () {
            for (let i = 0; i < this.lineMeshes.length; i++) {
                this.remove(this.lineMeshes[i])
                this.lineMeshes[i].geometry.dispose()
            }

            for (let i = 0; i < this.fillMeshes.length; i++) {
                this.fillMeshes[i].setClickable(false)
                this.remove(this.fillMeshes[i])
                this.fillMeshes[i].geometry.dispose()
            }
            this.fillMeshes.length = 0
            this.lineMeshes.length = 0
            this.triangles.length = 0
        },

        updateStyle: function () {
            if (this.__layer) {
                var style = this.__layer.getStyle(this)

                this.fillMat.color = new THREE.Color(style.fillColor)
                this.fillMat.opacity = style.fillOpacity * this.getOpacity()
                this.lineMaterial.color = new THREE.Color(style.lineColor)
                this.lineMaterial.opacity = style.lineOpacity * this.getOpacity()
            }
        },

        update: function () {
            this.cleanMeshes()

            for (let i = 0; i < this.polygons.length; i++) {
                this.updateGeomAt(i)
            }

            var area = 0

            for (let i = 0; i < this.triangles.length; i++) {
                area += this.calculateArea(this.triangles[i])
            }

            this.areaSize = (Math.round(area * 100) / 100) + 'm²'
            this.setLabel()

            this.position.set(0, 0, 0)
        }, // update end

        updateGeomAt: function (index) {
            var positionPoints = this.getPositionedPoints(index)
            var positionedHolePoints = this.getPositionedHolePoints()
            var boundsData = this.getBounds(positionPoints)
            var vPoints = this.updateLineVerticesAt(index, positionPoints, boundsData, positionedHolePoints)

            if (vPoints.length < 3) return

            var p1 = vPoints[0]
            var p2 = vPoints[1]
            var p3 = vPoints[2]

            var f = AnkaPanAPI.Utils.GetNormal(p1, p2, p3)
            var face = new THREE.Vector3(f.x, f.y, f.z)
            face.normalize()

            var cleanCo
            var cleanHoles
            var isOnGround
            if (Math.abs(face.z) > Math.abs(face.y) && Math.abs(face.z) > Math.abs(face.x)) {
                isOnGround = true
                cleanCo = this.getNonReapetedPoints(vPoints, 'x', 'y')

                if (this.holes && this.holes.length > 0) {
                    cleanHoles = []
                    for (let i = 0; i < positionedHolePoints.length; i++) {
                        var php = positionedHolePoints[i]
                        let cphp = this.getNonReapetedPoints(php, 'x', 'z')

                        if (cphp) {
                            for (let j = 0; j < cphp.length; j++) {
                                cphp[j]._x = cphp[j].x
                                cphp[j]._y = cphp[j].y
                                cphp[j]._z = cphp[j].z
                                cphp[j].y = cphp[j]._z
                                cphp[j].z = cphp[j]._y
                            }
                        }

                        cleanHoles.push(cphp)
                    }
                }
            } else {
                isOnGround = false
                cleanCo = this.getNonReapetedPoints(vPoints, 'x', 'z')

                if (this.holes && this.holes.length > 0) {
                    // cleanHoles = this.getNonReapetedPoints(positionedHolePoints, "x", "y");
                    cleanHoles = []
                    for (let i = 0; i < positionedHolePoints.length; i++) {
                        let cphp = this.getNonReapetedPoints(positionedHolePoints[i], 'x', 'y')
                        cleanHoles.push(cphp)
                    }
                }

                for (let i = 0; i < cleanCo.length; i++) {
                    cleanCo[i]._x = cleanCo[i].x
                    cleanCo[i]._y = cleanCo[i].y
                    cleanCo[i]._z = cleanCo[i].z

                    cleanCo[i].y = cleanCo[i]._z
                    cleanCo[i].z = cleanCo[i]._y
                }
            }

            if (cleanCo.length > 2) {
                var swctx = new poly2tri.SweepContext(cleanCo)

                if (cleanHoles) {
                    for (var i = 0; i < cleanHoles.length; i++) {
                        swctx.addHole(cleanHoles[i])
                    }
                }

                var tri
                window.swctx = swctx
                try {
                    tri = swctx.triangulate()
                } catch (e) {
                    this.canDraw = false
                    console.log(e)
                    return
                }

                this.canDraw = true
                var tar = tri.getTriangles()
                this.updateFillVerticesAt(index, tar, boundsData, isOnGround)
            } else {
                console.log('not enough points')
            }
        },

        getNonReapetedPoints: function (contour, key1, key2) {
            var repeat = {}
            var cleanCo = []
            for (var i = 0; i < contour.length; i++) {
                var id = contour[i][key1] + '_' + contour[i][key2]
                if (!repeat[id]) {
                    repeat[id] = 1
                    cleanCo.push(contour[i])
                } else {

                }
            }
            return cleanCo
        },

        getBounds: function (contour) {
            var mn = Number.MIN_SAFE_INTEGER
            var maxX = mn
            var maxY = mn
            var maxZ = mn

            var mx = Number.MAX_SAFE_INTEGER
            var minX = mx
            var minY = mx
            var minZ = mx
            for (var i = 0; i < contour.length; i++) {
                if (contour[i].x < minX) {
                    minX = contour[i].x
                }

                if (contour[i].y < minY) {
                    minY = contour[i].y
                }

                if (contour[i].z < minZ) {
                    minZ = contour[i].z
                }

                if (contour[i].x > maxX) {
                    maxX = contour[i].x
                }

                if (contour[i].y > maxY) {
                    maxY = contour[i].y
                }

                if (contour[i].z > maxZ) {
                    maxZ = contour[i].z
                }
            }

            var halfX = (maxX - minX) * 0.5
            var halfY = (maxY - minY) * 0.5
            var halfZ = (maxZ - minZ) * 0.5

            return {
                maxX: maxX,
                maxY: maxY,
                maxZ: maxZ,
                minX: minX,
                minY: minY,
                minZ: minZ,
                halfX: halfX,
                halfY: halfY,
                halfZ: halfZ
            }
        },

        updateFillVerticesAt: function (index, tar, boundData, isOnGround) {
            var vertexCount = 200
            var vpositions = new Float32Array(vertexCount * 3)
            var nomal = new Float32Array(vertexCount * 3)
            var indices = new Uint16Array(vertexCount)

            var fillGeom = new THREE.BufferGeometry()
            fillGeom.addAttribute('position', new THREE.BufferAttribute(vpositions, 3))
            fillGeom.addAttribute('nomal', new THREE.BufferAttribute(nomal, 3))
            fillGeom.setIndex(new THREE.BufferAttribute(indices, 1))

            var fill = new EventMesh(fillGeom, this.fillMat)
            fill.frustumCulled = false
            fillGeom.setDrawRange(0, 3)
            this.add(fill)

            fill.setClickable(true, this.panogl, null, this.panogl.getMainCamera())
            fill.addEvent(SGeom.CLICK, this, this.onClick)
            fill.addEvent(SGeom.MOUSE_DOWN, this, this.onDown)

            this.fillMeshes.push(fill)

            var fgeom = fillGeom
            var vertices = fgeom.attributes.position.array

            this.triangles.push(tar)

            var indexVer = 0
            var indicesIndex = 0
            var ind = 0
            for (var i = 0; i < tar.length; i++) {
                var pts = tar[i]
                pts = pts.getPoints()

                for (var j = 0; j < pts.length; j++) {
                    var p = pts[j]

                    if (isOnGround) {
                        vertices[indexVer++] = p.x
                        vertices[indexVer++] = p.z// (p.z - minY) + halfY;
                        vertices[indexVer++] = p.y// (p.y - minZ) + halfZ;
                    } else {
                        vertices[indexVer++] = p.x
                        vertices[indexVer++] = p.y// (p.z - minY) + halfY;
                        vertices[indexVer++] = p.z// (p.y - minZ) + halfZ;
                    }

                    var gi = ((i + 1) * 3)
                    ind = gi - (j + 1)

                    indices[indicesIndex++] = ind
                }
            }
            fgeom.setDrawRange(0, indexVer / 3)
            fgeom.attributes.position.needsUpdate = true
            fgeom.index.needsUpdate = true
        },

        updateLineVerticesAt: function (index, positionPoints, boundData, positionedHolePoints) {
            var vertexCount = positionPoints.length
            var geom = new THREE.BufferGeometry()
            var positions = new Float32Array(vertexCount * 3) // 3 vertices per point
            geom.addAttribute('position', new THREE.BufferAttribute(positions, 3))
            var line = new THREE.Line(geom, this.lineMaterial)
            line.frustumCulled = false
            this.lineMeshes.push(line)

            var vPoints = []
            geom = line.geometry
            var par = geom.attributes.position.array
            var indexVer = 0
            for (var i = 0; i < positionPoints.length; i++) {
                var pp = positionPoints[i]
                par[indexVer++] = pp.x
                par[indexVer++] = pp.y
                par[indexVer++] = pp.z
                vPoints.push({ x: pp.x, y: pp.z, z: pp.y, lon: pp.lon, lat: pp.lat, alt: pp.alt })
            }
            geom.setDrawRange(0, positionPoints.length)
            geom.attributes.position.needsUpdate = true

            geom.computeBoundingBox()
            this.add(line)
            return vPoints
        },

        getPositionedPoints: function (index) {
            var sp = this.polygons[index]

            var points = []
            for (var i = 0; i < sp.length; i++) {
                var p = sp[i]
                var pp = this.scalable.calculatePointPositionFromLonLatAlt(p.lon, p.lat, p.alt)
                pp.lat = p.lat
                pp.lon = p.lon
                pp.alt = p.alt
                points.push(pp)
            }

            return points
        },

        getPositionedHolePoints: function () {
            if (!this.holes || this.holes.length === 0) return

            var fullHoles = []
            for (var i = 0; i < this.holes.length; i++) {
                var holePoints = []
                var sp = this.holes[i]
                for (var j = 0; j < sp.length; j++) {
                    var p = sp[j]
                    var pp = this.scalable.calculatePointPositionFromLonLatAlt(p.lon, p.lat, p.alt)
                    pp.lat = p.lat
                    pp.lon = p.lon
                    pp.alt = p.alt
                    holePoints.push(pp)
                }
                fullHoles.push(holePoints)
            }

            return fullHoles
        },

        disableAll: function () {
            if (this.parent) {
                this.parent.remove(this)
            }
            this.enabled = false
        },

        destroy: function () {
            this.disableAll()
            this.cleanMeshes()
            if (this.labelHook) {
                this.softText && this.softText.removeObject3D(this.labelHook)
                this.softText = null
            }
        }

    })

export { SMultiPolygon }
