
function PathGeometry (points) {
    THREE.BufferGeometry.call(this)
    this.points = points
    this.createBufferPlaneGeometry()
}

PathGeometry.prototype = Object.assign(Object.create(THREE.BufferGeometry.prototype), {

    constructor: PathGeometry,

    createBufferPlaneGeometry: function () {
        var vertex = []
        var indices = []

        var indexCounter = 0
        var quarter = Math.PI / 2
        var size = this.points.length - 1
        var uv = []
        for (var i = 0; i < size; i++) {
            var p1 = this.points[i]
            var p2 = this.points[i + 1]

            var dx = p1.x - p2.x
            var dz = p1.z - p2.z
            var pWidth = (1024 / 3)
            var pHeight = (128 / 1024) * pWidth

            var dist = Math.sqrt((dx * dx) + (dz * dz)) / pWidth

            if (dist < 1) {
                pHeight = pHeight * dist
                dist = 1
            }

            dx = p1.x - p2.x
            dz = p1.z - p2.z

            var radian = Math.atan2(dx, dz)

            var leftX = p1.x + Math.sin(radian + quarter) * pHeight
            var leftZ = p1.z + Math.cos(radian + quarter) * pHeight

            var rightX = p1.x + Math.sin(radian - quarter) * pHeight
            var rightZ = p1.z + Math.cos(radian - quarter) * pHeight

            var left2X = p2.x + Math.sin(radian + quarter) * pHeight
            var left2Z = p2.z + Math.cos(radian + quarter) * pHeight

            var right2X = p2.x + Math.sin(radian - quarter) * pHeight
            var right2Z = p2.z + Math.cos(radian - quarter) * pHeight

            vertex.push(right2X, p2.y, right2Z)
            vertex.push(left2X, p2.y, left2Z)

            vertex.push(leftX, p1.y, leftZ)
            vertex.push(rightX, p1.y, rightZ)

            var inx1 = indexCounter
            var inx2 = indexCounter + 1
            var inx3 = indexCounter + 2
            var inx4 = indexCounter + 3
            indexCounter += 4

            indices.push(inx3, inx2, inx1)
            indices.push(inx1, inx4, inx3)

            var heightUV = dist
            uv.push(heightUV, 1)
            uv.push(heightUV, 0)
            uv.push(0, 0)
            uv.push(0, 1)
        }

        this.addAttribute('position', new THREE.Float32BufferAttribute(vertex, 3))
        this.addAttribute('uv', new THREE.Float32BufferAttribute(uv, 2))

        var indexArray = new Uint16Array(indices)
        this.setIndex(new THREE.BufferAttribute(indexArray, 1))

        this.setDrawRange(0, 100)
        this.attributes.position.needsUpdate = true
        this.attributes.uv.needsUpdate = true
        return this
    }

})

export {PathGeometry}
