import { SPoint } from './SPoint'
import { SLine } from './SLine'
import { SPolygon } from './SPolygon'
import { SMultiLine } from './SMultiLine'
import { SMultiPolygon } from './SMultiPolygon'
import { SMultiPoint } from './SMultiPoint'

GeomDataHolder.CHANGE = 'onChange'
GeomDataHolder.STATUS = {
    COMPLETED: 1,
    DRAWING: 2,
    BAD_COMPLETED: 3
}

let regex = {
    pointType: /^(POINT ZM|POINT Z|POINT)/i,
    lineType: /^(linestring ZM|LINESTRING Z|LINESTRING)/i,
    polygonType: /^(POLYGON ZM|POLYGON Z|POLYGON)/i,
    multi: /^(multi)/i,
    multiLineString: /^(MultiLineString ZM|MultiLineString Z|MultiLineString)/i,
    multiPoint: /^(multiPoint ZM|multiPoint Z|multiPoint)/i,
    multiPolygon: /^(MultiPolygon ZM|MultiPolygon Z|MultiPolygon)/i
}

GeomDataHolder.POLYGON = 'Polygon'
GeomDataHolder.LINE = 'Line'
GeomDataHolder.POINT = 'Point'
GeomDataHolder.HLINE = 'Hline'
GeomDataHolder.CLICK = 'onClick'
GeomDataHolder.SELECT = 'onSelect'
GeomDataHolder.DESELECT = 'onDeSelect'
GeomDataHolder.MOVE_EDIT = 'onMoveEdit'
GeomDataHolder.MOVE_EDIT_STOP = 'onMoveEditStop'
GeomDataHolder.VERTEX_EDIT = 'onVertexEdit'
GeomDataHolder.VERTEX_EDIT_STOP = 'onVertexEditStop'
GeomDataHolder.___uicounter = 12
function GeomDataHolder(sampleClass, points, attributes) {
    AnkaPanAPI.Base.apply(this, [])
    this.layer = null
    this.isDrawnData = false
    this.points = points
    this.holes = null
    this.referanceCounter = 0
    this.attributes = attributes === undefined || attributes === null ? {} : attributes

    this.setSampleClass(sampleClass)

    this.isMultiGeom = sampleClass.isMultiGeom
    this.__isSelected = false
    GeomDataHolder.___uicounter++
    this.userData = {}

    // UniqueID is something needs to be never changed.
    // this.attributes.__uniqueID = ;
    var uniqueID = GeomDataHolder.___uicounter

    var getters = {
        get: function () {
            return uniqueID
        },
        set: function (v) {
            throw new Error('Cannot be set any value')
        }
    }

    Object.defineProperty(this, 'uniqueID', getters)
    Object.defineProperty(this.attributes, '__uniqueID', getters)
}

GeomDataHolder.prototype = Object.assign(Object.create(AnkaPanAPI.Base.prototype), {
    constructor: GeomDataHolder,
    isCompleted() {
        return GeomDataHolder.STATUS.COMPLETED === this.currentStatus
    },
    isDrawing() {
        return GeomDataHolder.STATUS.DRAWING === this.currentStatus
    },
    addRC: function () {
        return ++this.referanceCounter
    },
    removeRC: function () {
        return --this.referanceCounter
    },
    hasReferance: function () {
        return this.referanceCounter > 0
    },
    setPoints: function (points) {
        this.points = points
        this.throwEvent({ type: GeomDataHolder.CHANGE, target: this })
    },

    resetPoints: function () {
        this.points.length = 0
    },

    setSampleClass: function (sampleClass) {
        var points = this.points
        if (sampleClass === SPoint) {
            this.type = GeomDataHolder.POINT
        } else if (sampleClass === SLine || sampleClass === SMultiLine) {
            this.type = GeomDataHolder.LINE
        } else if (sampleClass === SPolygon || sampleClass === SMultiPolygon) {
            this.type = GeomDataHolder.POLYGON

            if (sampleClass === SPolygon) {
                let pts = points
                if (pts.length > 1) {
                    let firstPT = pts[0]
                    let lastPT = pts[pts.length - 1]
                    if (lastPT.lat === firstPT.lat && lastPT.lon === firstPT.lon && lastPT.lon === firstPT.lon) {
                        pts.splice(pts.length - 1, 1)
                    }
                }
            } else {
                for (let i = 0; i < points.length; i++) {
                    let pts = points[i]

                    if (pts.length > 1) {
                        let firstPT = pts[0]
                        let lastPT = pts[pts.length - 1]

                        if (lastPT.lat === firstPT.lat && lastPT.lon === firstPT.lon && lastPT.lon === firstPT.lon) {
                            pts.splice(pts.length - 1, 1)
                        }
                    }
                }
            }
        }

        this.sampleClass = sampleClass
    },

    isMoveEditing: function () {
        return this._isMoveEditing
    },

    editMove: function () {
        this._isMoveEditing = true
        this.throwEvent({ type: GeomDataHolder.MOVE_EDIT, target: this })
    },

    stopMoveEdit: function () {
        this._isMoveEditing = false
        this.throwEvent({ type: GeomDataHolder.MOVE_EDIT_STOP, target: this })
    },

    editVertex: function () {
        if (!this.isVertexEditing()) {
            this._isVertextEditing = true
            this.throwEvent({ type: GeomDataHolder.VERTEX_EDIT, target: this })
        }
    },

    stopVertexEdit: function () {
        if (this.isVertexEditing()) {
            this._isVertextEditing = false
            this.throwEvent({ type: GeomDataHolder.VERTEX_EDIT_STOP, target: this })
        }
    },

    isVertexEditing: function () {
        return this._isVertextEditing
    },

    // TODO delete in future versions
    setSelection: function (b) {
        console.warn('setSelection is deprecated. Use select or deselect ')
        if (this.__isSelected !== b) {
            if (b) {
                this.__isSelected = b
                this.throwEvent({ type: GeomDataHolder.SELECT, target: this })
            } else {
                this.__isSelected = b
                this.throwEvent({ type: GeomDataHolder.DESELECT, target: this })
            }
        }
    },

    select: function () {
        if (!this.__isSelected) {
            this.__isSelected = true
            this.throwEvent({ type: GeomDataHolder.SELECT, target: this })
        }
    },

    deselect: function () {
        if (this.__isSelected) {
            this.__isSelected = false
            this.throwEvent({ type: GeomDataHolder.DESELECT, target: this })
        }
    },

    isSelected: function () {
        return this.__isSelected
    },
    getKeyValue: function (key) {
        return this.attributes[key]
    },
    setAttributes: function (attributes) {
        this.attributes = attributes
        this.throwEvent({ type: GeomDataHolder.CHANGE, target: this })
    },

    mergeAttributes: function (attributes) {
        for (var str in attributes) {
            this.attributes[str] = attributes[str]
        }
        this.throwEvent({ type: GeomDataHolder.CHANGE, target: this })
    },

    setAttribute: function (key, value) {
        this.attributes[key] = value
        this.throwEvent({ type: GeomDataHolder.CHANGE, target: this })
    },

    addOffset: function (lon, lat, alt, currentAlt) {
        if (this.sampleClass.isMultiGeom) {
            for (let i = 0; i < this.points.length; i++) {
                let pts = this.points[i]
                for (let j = 0; j < pts.length; j++) {
                    pts[j].lon += lon
                    pts[j].lat += lat

                    if (!isNaN(pts[j].alt)) {
                        pts[j].alt += alt
                    } else {
                        pts[j].alt = currentAlt + alt
                    }
                }
            }
        } else {
            for (let i = 0; i < this.points.length; i++) {
                let pt = this.points[i]
                pt.lon += lon
                pt.lat += lat

                if (!isNaN(pt.alt)) {
                    pt.alt += alt
                } else {
                    pt.alt = currentAlt + alt
                }
            }
        }

        this.throwEvent({ type: GeomDataHolder.CHANGE, target: this })
    },

    addPoint: function (pt) {
        this.points.push(pt)
        this.throwEvent({ type: GeomDataHolder.CHANGE, target: this })
    },

    removeLastPoint: function () {
        if (this.points.length > 0) {
            this.points.pop()
            this.throwEvent({ type: GeomDataHolder.CHANGE, target: this })
        }
    },

    setDynamicPoint: function (p) {
        this.dynamicPoint = p
        this.throwEvent({ type: GeomDataHolder.CHANGE, target: this, dynamicPoint: this.dynamicPoint })
    },

    cloneFrom: function (g) {
        this.points = g.points
    },

    setPointAt: function (p, value) {
        if (p < 0) return
        this.points[p] = value
        this.throwEvent({ type: GeomDataHolder.CHANGE, target: this })
    },

    setStatus: function (s) {
        this.currentStatus = s
        this.throwEvent({ type: GeomDataHolder.CHANGE, target: this })
    },

    complete() {
        this.setStatus(GeomDataHolder.STATUS.COMPLETED)
        this.throwEvent({ type: GeomDataHolder.CHANGE, target: this })
    },

    getStatus: function () {
        return this.currentStatus
    },

    getPointLength: function () {
        return this.points ? this.points.length : 0
    },

    notifyPointChange: function () {
        this.throwEvent({ type: GeomDataHolder.CHANGE, target: this })
    },

    notifyClick: function () {
        this.throwEvent({ type: GeomDataHolder.CLICK, target: this })
    },

    toWKT: function () {
        if (this.sampleClass.isMultiGeom) {
            if (this.sampleClass === SMultiPolygon) {
                let pstr = 'MULTIPOLYGON Z ('

                for (let i = 0; i < this.points.length; i++) {
                    let pts = this.points[i]
                    let str = '(('
                    for (let j = 0; j < pts.length; j++) {
                        let p = pts[j]

                        if (j > 0) {
                            str += ','
                        }
                        str += p.lon + ' ' + p.lat + ' ' + (isNaN(p.alt) ? 0 : p.alt)
                    }

                    str += '))'

                    if (i > 0) {
                        str += ','
                    }

                    pstr += str
                }
                pstr += ')'

                return pstr
            }
        } else {
            if (this.points.length === 1) {
                let p = this.points[0]
                return 'POINT (' + p.lon + ' ' + p.lat + ' ' + p.alt + ')'
            } else {
                let pts = this.points
                let str = ''
                for (let i = 0; i < pts.length; i++) {
                    if (i > 0) {
                        str += ','
                    }
                    str += pts[i].lon + ' ' + pts[i].lat + ' ' + pts[i].alt
                }

                if (this.type === GeomDataHolder.POLYGON) {
                    return 'Polygon ((' + str + '))'
                } else if (this.type === GeomDataHolder.LINE) {
                    return 'LINESTRING (' + str + ')'
                }
            }
        }
    },

    toGeoJSON: function () {
        if (this.points.length === 0) {
            return null
        }

        if (this.type === GeomDataHolder.POINT || this.type === GeomDataHolder.HLINE) {
            let feature = {}
            feature.type = 'Feature'
            let p = this.points[0]
            feature.geometry = {}
            feature.geometry.type = 'Point'
            feature.geometry.coordinates = [p.lon, p.lat, p.alt]
            feature.properties = { srid: 4236 }

            feature.ankaProperties = {}
            for (let str in this.attributes) {
                if (str[0] !== '_') {
                    feature.properties[str] = this.attributes[str]
                } else {
                    feature.ankaProperties[str] = this.attributes[str]
                }
            }
            return feature
        } else if (this.type === GeomDataHolder.LINE) {
            var feature = {}
            feature.type = 'Feature'
            feature.geometry = {}
            feature.geometry.coordinates = []
            feature.properties = { srid: 4236 }
            feature.ankaProperties = {}

            for (let str in this.attributes) {
                if (str[0] !== '_') {
                    feature.properties[str] = this.attributes[str]
                } else {
                    feature.ankaProperties[str] = this.attributes[str]
                }
            }

            if (!this.sampleClass.isMultiGeom) {
                feature.geometry.type = 'LineString'
                for (let i = 0; i < this.points.length; i++) {
                    let p = this.points[i]
                    feature.geometry.coordinates.push([p.lon, p.lat, p.alt])
                }
            } else {
                feature.geometry.type = 'MultiLineString'
                for (let i = 0; i < this.points.length; i++) {
                    let paths = this.points[i]
                    let newPath = []
                    for (let j = 0; j < paths.length; j++) {
                        let p = paths[j]
                        newPath.push([p.lon, p.lat, p.alt])
                    }
                    feature.geometry.coordinates.push(newPath)
                }
            }

            return feature
        } else if (this.type === GeomDataHolder.POLYGON) {
            let feature = {}
            feature.type = 'Feature'
            let p = this.points[0]
            feature.geometry = {}

            feature.geometry.coordinates = []
            feature.properties = {}
            feature.ankaProperties = {}

            for (let str in this.attributes) {
                if (str[0] !== '_') {
                    feature.properties[str] = this.attributes[str]
                } else {
                    feature.ankaProperties[str] = this.attributes[str]
                }
            }

            if (!this.sampleClass.isMultiGeom) {
                let polygon = []
                feature.geometry.type = 'Polygon'
                for (let i = 0; i < this.points.length; i++) {
                    p = this.points[i]
                    polygon.push([p.lon, p.lat, p.alt])
                }

                let fp = polygon[0]
                let lp = polygon[polygon.length - 1]

                if (fp[0] !== lp[0] || fp[1] !== lp[1] || fp[2] !== lp[2]) {
                    polygon.push([fp[0], fp[1], fp[2]])
                }

                feature.geometry.coordinates[0] = polygon
            } else {
                feature.geometry.type = 'MultiPolygon'
                for (let i = 0; i < this.points.length; i++) {
                    let paths = this.points[i]
                    let polygon = []
                    for (let j = 0; j < paths.length; j++) {
                        let p = paths[j]
                        polygon.push([p.lon, p.lat, p.alt])
                    }

                    let fp = polygon[0]
                    let lp = polygon[polygon.length - 1]
                    if (fp[0] !== lp[0] || fp[1] !== lp[1] || fp[2] !== lp[2]) {
                        polygon.push([fp[0], fp[1], fp[2]])
                    }

                    feature.geometry.coordinates.push([polygon])
                }
            }

            return feature
        } else {
            console.log('Type is not supported')
        }
    }

})

function getPoints(wkt) {
    if (wkt) {
        if (regex.multi.test(wkt)) {
            // var replaceType = /([A-Z])\w+/g
            var insidePar = /\(|\)/g
            if (regex.multiLineString.test(wkt)) { // LINE
                let totalLines = []
                let newWKT = wkt.replace(regex.multiLineString, '').trim()
                let lines = newWKT.split('),(')

                for (let i = 0; i < lines.length; i++) {
                    let rawSTR = lines[i].trim()
                    let lineSTR = rawSTR.replace(insidePar, '')
                    let lineAr = lineSTR.split(',')
                    let line = []
                    for (let j = 0; j < lineAr.length; j++) {
                        let point = lineAr[j].split(' ')
                        let pt = { lon: parseFloat(point[0]), lat: parseFloat(point[1]), alt: parseFloat(point[2]) }
                        line.push(pt)
                    }

                    totalLines.push(line)
                }

                return { geomCount: totalLines.length, geoms: totalLines }
            } else if (regex.multiPolygon.test(wkt)) { // POLYGON
                let totalPol = []
                let newWKT = wkt.replace(regex.multiPolygon, '').trim()
                let polygons = newWKT.split(')),((')

                for (let i = 0; i < polygons.length; i++) {
                    let polySTR = polygons[i]
                    let pointSTR = polySTR.replace(insidePar, '').trim()
                    let points = pointSTR.split(',')
                    let pts = []

                    for (let j = 0; j < points.length; j++) {
                        let p = points[j].trim()
                        let ps = p.split(' ')
                        pts.push({ lon: parseFloat(ps[0]), lat: parseFloat(ps[1]), alt: parseFloat(ps[2]) })
                    }
                    totalPol.push(pts)
                }

                return { geomCount: 1, geoms: totalPol }
            } else if (regex.multiPoint.test(wkt)) {
                let totalPol = []
                let newWKT = wkt.replace(regex.multiPoint, '').trim()
                let pointsGroup = newWKT.split(')),((')

                for (let i = 0; i < pointsGroup.length; i++) {
                    let polySTR = pointsGroup[i]
                    let pointSTR = polySTR.replace(insidePar, '').trim()
                    let points = pointSTR.split(',')
                    let pts = []
                    for (let j = 0; j < points.length; j++) {
                        let p = points[j].trim()
                        let ps = p.split(' ')
                        pts.push({ lon: parseFloat(ps[0]), lat: parseFloat(ps[1]), alt: parseFloat(ps[2]) })
                    }
                    totalPol.push(pts)
                }

                return { geomCount: 1, geoms: totalPol[0] }
            }
        } else {
            // let replaceType = /([A-Z])\w+/g
            let replacePar = /\(|\)/g

            if (regex.pointType.test(wkt)) {
                let newWKT = wkt.replace(regex.pointType, '')
                let pointSTR = newWKT.replace(replacePar, '').trim()
                let points = pointSTR.split(' ')
                let ar = [{ lon: parseFloat(points[0]), lat: parseFloat(points[1]), alt: parseFloat(points[2]) }]
                return { geomCount: 1, geoms: ar }
            } else if (regex.lineType.test(wkt)) {
                let newWKT = wkt.replace(regex.lineType, '')
                let pointSTR = newWKT.replace(replacePar, '').trim()
                let points = pointSTR.split(',')
                let pts = []
                for (let i = 0; i < points.length; i++) {
                    let p = points[i].trim()
                    let ps = p.split(' ')
                    pts.push({ lon: parseFloat(ps[0]), lat: parseFloat(ps[1]), alt: parseFloat(ps[2]) })
                }
                return { geomCount: 1, geoms: pts }
            } else if (regex.polygonType.test(wkt)) {
                let newWKT = wkt.replace(regex.polygonType, '')
                let geomAndHole = newWKT.split('),(')
                if (geomAndHole.length > 1) {
                    let pointSTR = geomAndHole[0].replace(replacePar, '').trim()
                    let points = pointSTR.split(',')
                    let pts = []
                    for (let i = 0; i < points.length; i++) {
                        let p = points[i].trim()
                        let ps = p.split(' ')
                        pts.push({ lon: parseFloat(ps[0]), lat: parseFloat(ps[1]), alt: parseFloat(ps[2]) })
                    }

                    let allHoles = []
                    for (let i = 1; i < geomAndHole.length; i++) {
                        let holesSTR = geomAndHole[i].replace(replacePar, '').trim()
                        let holePoints = holesSTR.split(',')
                        let holes = []
                        for (let j = 0; j < holePoints.length; j++) {
                            let p = holePoints[j].trim()
                            let ps = p.split(' ')
                            holes.push({ lon: parseFloat(ps[0]), lat: parseFloat(ps[1]), alt: parseFloat(ps[2]) })
                        }
                        allHoles.push(holes)
                    }

                    return { geomCount: 1, geoms: pts, holes: allHoles }
                } else {
                    let pointSTR = newWKT.replace(replacePar, '').trim()
                    let points = pointSTR.split(',')
                    let pts = []
                    for (let i = 0; i < points.length; i++) {
                        let p = points[i].trim()
                        let ps = p.split(' ')
                        pts.push({ lon: parseFloat(ps[0]), lat: parseFloat(ps[1]), alt: parseFloat(ps[2]) })
                    }
                    return { geomCount: 1, geoms: pts }
                }
            }
        }
    }
}

function getWKTTypeClass(wkt) {
    let geomClass

    if (regex.multi.test(wkt)) {
        if (regex.multiLineString.test(wkt)) {
            geomClass = SMultiLine
        } else if (regex.multiPolygon.test(wkt)) {
            geomClass = SMultiPolygon
        } else if (regex.multiPoint.test(wkt)) {
            geomClass = SPoint
        } else {
            console.warn('Unsupported type')
        }
    } else {
        if (regex.pointType.test(wkt)) {
            geomClass = SPoint
        } else if (regex.lineType.test(wkt)) {
            geomClass = SLine
        } else if (regex.polygonType.test(wkt)) {
            geomClass = SPolygon
        } else {
            console.warn('Unsupported type')
        }
    }
    return geomClass
}

GeomDataHolder.fromWKT = function (wkt, attributes) {
    let geomClass = getWKTTypeClass(wkt)
    if (geomClass) {
        let points = getPoints(wkt)
        let geoms = points.geoms
        let holes = points.holes
        let geom = new GeomDataHolder(geomClass, geoms, attributes)
        geom.holes = holes
        geom.complete()
        return geom
    }
}

/**
 * -5000 altitude mobil verisi için atıldı.
 * Dünyanın en aptalca şeyi ama anlatamadım.
 */
GeomDataHolder.fromGeoJSON = function (geoJson) {
    let geometry = geoJson.geometry
    let geomType = geometry.type
    let coordinates = geometry.coordinates
    let properties = geoJson.properties
    let uniqueId = geoJson.id

    if (uniqueId === undefined) console.warn("feature's uniq ID could not be found!")

    if (regex.multi.test(geomType)) {
        if (regex.multiLineString.test(geomType)) {
            coordinates = coordinates.map(line => line
                .map(([lon, lat, alt]) => {
                    if (alt === -5000) {
                        alt = NaN
                    }
                    return { lon, lat, alt }
                }))
            let gdh = new GeomDataHolder(SMultiLine, coordinates, Object.assign({ __geomKey: uniqueId }, properties))
            gdh.complete()
            return gdh
        } else if (regex.multiPoint.test(geomType)) {
            coordinates = coordinates.map(([lon, lat, alt]) => {
                if (alt === -5000) {
                    alt = NaN
                }
                return { lon, lat, alt }
            })
            let gdh = new GeomDataHolder(SMultiPoint, coordinates, Object.assign({ __geomKey: uniqueId }, properties))
            gdh.complete()
            return gdh
        } else if (regex.multiPolygon.test(geomType)) {
            let mgHoles = []
            const multiPolygon = coordinates.map((polygon) => {

                polygon.concat().splice(1).forEach((holes) => {

                    const mappedHoles = holes.map((point) => {
                        let [lon, lat, alt] = point;
                        if (alt === -5000) {
                            alt = NaN
                        }
                        return { lon: +lon, lat: +lat, alt: +alt }
                    })

                    if (mgHoles.length === 0) { // this is wrong but for now, we don't need to support multiple hole. Never needed but for cloud?
                        mgHoles = mappedHoles
                    }
                })

                return polygon[0]
                    .map((point) => {
                        let [lon, lat, alt] = point;
                        if (alt === -5000) {
                            alt = NaN
                        }
                        return { lon: +lon, lat: +lat, alt: +alt }
                    });
            })


            const gdh = new GeomDataHolder(SMultiPolygon, multiPolygon, Object.assign({ __geomKey: uniqueId }, properties))
            gdh.holes = mgHoles;
            gdh.complete()
            return gdh
        } else {
            console.log('Geom type could not be found or recognized', geomType)
        }
    } else {
        console.log('Non multi is not supported')
    }
}

export { GeomDataHolder }
