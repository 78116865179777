import { GeomDataHolder } from './GeomDataHolder'
import { SGeom } from './SGeom'
import { EventMesh } from './EventMesh'
import { WallGeometry } from './WallGeometry'

Layer.MOVE_EVENT = 'onMouseMove'
Layer.FEATURE_CLICK = 'onClickFeature'
Layer.SELECT_FEATURE = 'onSelectFeature'
Layer.DESELECT_FEATURE = 'onDeselectFeature'

Layer.MOVE_STATUS = {
    STARTED: 'STARTED',
    COMPLETE: 'COMPLETE',
    MOVING: 'MOVING'
}

Layer.STATES = {
    NORMAL: 'NORMAL',
    MOVE: 'MOVE',
    VERTEX_EDIT: 'VERTEX_EDIT'
}

function createDefaultEvent (type, sgeom, layer, gdh) {
    return { type, target: sgeom, layer, gdh }
}

Layer.__instances = {}
Layer.__instancesValues = {}
function Layer (layerName, keyID, geomType, style) {
    this.isSketchLayer = false
    this.__visible = true
    this.__labelTextSample = ''
    this.__layerOpacity = 1
    this.__meshStorage = {}
    this.name = layerName
    this.__keyID = keyID
    this.__layerID = 'id_' + layerName
    this._isLabelVisible = true
    if (!Layer.__instances[this.__layerID]) {
        Layer.__instances[this.__layerID] = []
        Layer.__instancesValues[this.__layerID] = {}
        Layer.__instancesValues[this.__layerID].currentState = Layer.STATES.NORMAL
        Layer.__instancesValues[this.__layerID].commonGeometries = []
        Layer.__instancesValues[this.__layerID].drawingGeoms = []
    }

    this.setState(Layer.STATES.NORMAL)

    Layer.__instances[this.__layerID].push(this)

    this.__geomType = geomType
    this._geometries = []
    AnkaPanAPI.Base.apply(this, [])
    this.__objectContainer = new THREE.Object3D()
    this.__objectContainer.mouseDisabled = true

    this.releaseAnchor = this.releaseAnchor.bind(this)
    this.onMouseUpOnGeom = this.onMouseUpOnGeom.bind(this)
    this.__style =
        {
            lineWidth: 5,
            lineColor: 0xff0000,
            fillColor: 0xffff00,
            fillOpacity: 0.4,
            lineOpacity: 0.6
        }

    if (style) { this.setStyle(style) }

    Object.defineProperty(this, 'visible', {

        set: function (v) {
            this.__visible = v
            if (this.__visible) {
                this.refresh()
            } else {
                this.removeAllChildren()
            }
            this.scalable.setDirty()
        },

        get: function () {
            return this.__visible
        }

    })
}

Layer.prototype = Object.assign(Object.create(AnkaPanAPI.Base.prototype),
    {
        constructor: Layer,
        setLabelVisibility: function (b) {
            if (this._isLabelVisible !== b) {
                this._isLabelVisible = b

                for (var str in this.__meshStorage) {
                    this.__meshStorage[str].setLabel()
                }
            }

            if (this.scalable) {
                this.scalable.setDirty()
            }
        },

        getLabelVisibility: function () {
            return this._isLabelVisible
        },

        setMouseEnable: function (b) {
            this.__objectContainer.mouseDisabled = !b
        },

        setStyle: function (style) {
            this.__style = style
        },

        add: function (_geomObject) {
            console.warn('add method is deprecated')
        },

        addGlobal: function (gdh) {
            if (!(gdh instanceof GeomDataHolder)) {
                throw new TypeError('param should be GeomDataHolder')
            }

            let newGeomValue = gdh.getKeyValue(this.__keyID)
            let geomPool = Layer.__instancesValues[this.__layerID].commonGeometries

            let gdhToBeAdded = gdh
            for (let i = 0; i < geomPool.length; i++) {
                let value = geomPool[i].getKeyValue(this.__keyID)
                if (newGeomValue === value) {
                    gdhToBeAdded = geomPool[i]
                    break
                }
            }

            if (geomPool.indexOf(gdhToBeAdded) === -1) {
                geomPool.push(gdhToBeAdded)
            }
        },

        removeFromGlobal: function (gdh) {
            let geomPool = Layer.__instancesValues[this.__layerID].commonGeometries
            gdh.removeRC()
            if (!gdh.hasReferance()) {
                var index = geomPool.indexOf(gdh)
                geomPool.splice(index, 1)
            }
        },

        addToList: function (gdh) {
            console.warn("addToList will be deprecated. Use 'addToLocalAndGlobal', 'addGlobal', 'addToLocal' instead")
            // this.addToLocalAndGlobal(gdh)
            this.addGlobal(gdh)
        },

        addToLocalAndGlobal: function (gdh) {
            this.addGlobal(gdh)
            this.addToLocal(gdh)
        },

        removeFromLocal (gdh) {
            let geometries = this._geometries
            let index = geometries.indexOf(gdh)
            if (index > -1) {
                geometries.splice(index, 1)
                gdh.removeRC()
            }
        },

        addToLocal (gdh) {
            if (!(gdh instanceof GeomDataHolder)) {
                throw new TypeError('Object should be a geomdata hodler')
            }

            if (this._geometries.indexOf(gdh) === -1) {
                gdh.addRC()
                this._geometries.push(gdh)
            }

            if (!gdh.layer) {
                gdh.layer = this
            }
        },

        addToDrawingList: function (geom) {
            var drawingGeomPool = Layer.__instancesValues[this.__layerID].drawingGeoms
            var hasSame = 0
            for (var i = 0; i < drawingGeomPool.length; i++) {
                if (geom === drawingGeomPool[i]) {
                    hasSame = !0
                }
            }

            if (!hasSame) {
                drawingGeomPool.push(geom)
            }
        },

        setScalable: function (scalable) {
            this.scalable = scalable
            this.scalable.scalableScene.add(this.__objectContainer)
        },

        drawGeometry: function (gdh) {
            if (this.__visible) {
                gdh.removeEventWithContext(GeomDataHolder.SELECT, this, this.onGeomSelect)
                gdh.removeEventWithContext(GeomDataHolder.DESELECT, this, this.onGeomDeselect)
                gdh.removeEventWithContext(AnkaScalable.GeomDataHolder.MOVE_EDIT, this, this.onMoveStart)
                gdh.removeEventWithContext(AnkaScalable.GeomDataHolder.VERTEX_EDIT, this, this.onVertexEdit)
                gdh.removeEventWithContext(AnkaScalable.GeomDataHolder.VERTEX_EDIT_STOP, this, this.onVertexEditStop)
                gdh.removeEventWithContext(AnkaScalable.GeomDataHolder.CHANGE, this, this.onChangeGeom)
                gdh.removeEventWithContext(AnkaScalable.GeomDataHolder.MOVE_EDIT_STOP, this, this.onMoveEditStop)

                if (gdh.currentStatus === AnkaScalable.GeomDataHolder.STATUS.COMPLETED) {
                    let TempClass = gdh.sampleClass
                    let sGeom = new TempClass(this.scalable, gdh) // new SPolygon(this);
                    sGeom.enable(this.scalable.baseObject)
                    sGeom.setData(gdh.points, gdh.attributes, gdh.holes)
                    sGeom.setLayer(this)
                    sGeom.update()
                    sGeom.stopDraw()
                    sGeom.updateStyle()
                    sGeom.addEvent(SGeom.CLICK, this, this.onSGeomSelect)
                    sGeom.uniqueID = gdh.uniqueID

                    this.__objectContainer.add(sGeom)
                    this.__meshStorage[sGeom.uniqueID] = sGeom

                    if (gdh.isSelected()) {
                        sGeom.onSelect()
                    }

                    if (gdh.isVertexEditing()) {
                        this._editVertexGDH(gdh)
                    }

                    if (gdh.isMoveEditing()) {
                        sGeom.addEvent(SGeom.MOUSE_DOWN, this, this.onMouseDownOnGeom)
                    }

                    this.drawWalls(gdh, sGeom)
                    gdh.addEvent(AnkaScalable.GeomDataHolder.VERTEX_EDIT, this, this.onVertexEdit)
                    gdh.addEvent(AnkaScalable.GeomDataHolder.VERTEX_EDIT_STOP, this, this.onVertexEditStop)
                    gdh.addEvent(AnkaScalable.GeomDataHolder.CHANGE, this, this.onChangeGeom)
                    gdh.addEvent(AnkaScalable.GeomDataHolder.MOVE_EDIT, this, this.onMoveStart)
                    gdh.addEvent(AnkaScalable.GeomDataHolder.MOVE_EDIT_STOP, this, this.onMoveEditStop)
                } else {
                    let TempClass = gdh.sampleClass
                    let p = new TempClass(this.scalable, gdh)
                    p.enable(this.scalable.baseObject)
                    p.setData(gdh.points, gdh.attributes, gdh.holes)
                    p.setLayer(this)
                    p.update()
                    p.updateStyle()
                    p.addEvent(SGeom.CLICK, this, this.onSGeomSelect)
                    p.uniqueID = gdh.uniqueID

                    this.__objectContainer.add(p)
                    this.__meshStorage[p.uniqueID] = p
                }

                gdh.addEvent(GeomDataHolder.SELECT, this, this.onGeomSelect)
                gdh.addEvent(GeomDataHolder.DESELECT, this, this.onGeomDeselect)
            }
        },

        onMoveStart: function (event) {
            const gdh = event.target
            const sgeom = this.__meshStorage[gdh.uniqueID]
            if (sgeom) {
                sgeom.addEvent(SGeom.MOUSE_DOWN, this, this.onMouseDownOnGeom)
            }
        },
        onPolygonMove: function (e) {
            let sGeom = this._sGeomOnDrag
            if (sGeom) {
                const gdh = this.getGeomByUniqueID(sGeom.uniqueID)
                if (!gdh) {
                    return console.warn('Data holde could not be found')
                }

                let ancho = this.getTransformer()
                const panogl = this.scalable.baseObject
                const gm = panogl.getGroundMaster()
                const pos = gm.canvasToCartesian(panogl.globalMouseOffsetX, panogl.globalMouseOffsetY, this._dragStartY)

                if (pos) {
                    ancho.position.set(pos.x, pos.y, pos.z)
                }

                const meshDragStartPos = this._meshDragStartPos

                var lastPos = gm.posToLoc(pos)
                var offsetLat = lastPos.lat - meshDragStartPos.lat
                var offsetLon = lastPos.lon - meshDragStartPos.lon
                var offsetAlt = 0

                this._meshDragStartPos = lastPos
                gdh.addOffset(offsetLon, offsetLat, offsetAlt)
            }
        },

        onMouseUpOnGeom: function (e) {
            const panogl = this.scalable.baseObject
            const controller = panogl.getController()
            controller.disable(false)
            document.removeEventListener('mouseup', this.onMouseUpOnGeom)
            panogl.removeEvent(AnkaPanAPI.PanoGL.PRE_RENDER, this.onPolygonMove)
            this._draggingGeom = null
            this._meshDragStartPos = null
        },

        onMouseDownOnGeom: function (event) {
            const sGeom = event.currentTarget
            const panogl = this.scalable.baseObject
            const gm = panogl.getGroundMaster()
            const posY = event.intersectPoint.y
            const cartesianPos = gm.canvasToCartesian(panogl.globalMouseOffsetX, panogl.globalMouseOffsetY, posY)
            this._mouseDownPos = cartesianPos
            this._meshDragStartPos = gm.posToLoc(cartesianPos)
            this._firstPoint = this._meshDragStartPos
            this._stopControlDrag(true)

            this._dragStartY = posY
            this._sGeomOnDrag = sGeom

            panogl.addEvent(AnkaPanAPI.PanoGL.PRE_RENDER, this, this.onPolygonMove)
            document.addEventListener('mouseup', this.onMouseUpOnGeom)
        },

        onChangeGeom: function (event) {
            const gdh = event.target
            const mesh = this.__meshStorage[gdh.uniqueID]
            if (mesh) {
                mesh.setData(gdh.points, gdh.attributes)
                mesh.update()
                this.setDirty()
            }
        },

        onVertexEdit: function (event) {
            const gdh = event.target
            this._editVertexGDH(gdh)
            this.setDirty()
        },

        onVertexEditStop: function (_event) {
            var panogl = this.scalable.baseObject
            let drawingPlugin = typeof panogl.getDrawingPlugin !== 'function' || panogl.getDrawingPlugin()
            drawingPlugin.stopGeometryVertexEdit()
            this.setDirty()
        },

        onMoveEditStop: function (_event) {
            // onMoveEditStop
            this.hideTransformer()
            this.setDirty()
        },

        _editVertexGDH: function (gdh) {
            var panogl = this.scalable.baseObject
            let drawingPlugin = typeof panogl.getDrawingPlugin !== 'function' || panogl.getDrawingPlugin()

            if (!drawingPlugin) {
                throw new Error('AnkaDraw plugin could not be found!')
            }

            const sGeom = this.__meshStorage[gdh.uniqueID]
            if (sGeom) {
                drawingPlugin.editGeometryVertices(sGeom, gdh)
            }
        },

        redraw: function () {
            if (this.__visible) {
                this.findAndSetState()
                this.removeAllChildren()

                if (this.scalable) {
                    var sd = this._geometries
                    for (let i = 0; i < sd.length; i++) {
                        this.drawGeometry(sd[i])
                    }
                }

                var drawingGeomPool = Layer.__instancesValues[this.__layerID].drawingGeoms
                for (let i = 0; i < drawingGeomPool.length; i++) {
                    this.drawGeometry(drawingGeomPool[i])
                }

                this.setDirty()
            }
        },

        clearAllDrawingData: function () {
            var drawingGeomPool = Layer.__instancesValues[this.__layerID].drawingGeoms
            drawingGeomPool.length = 0
            var lins = Layer.__instances[this.__layerID]
            for (var i = 0; i < lins.length; i++) {
                lins[i].clearDrawingData()
            }
        },

        clearDrawingData: function () {
            var children = this.__objectContainer.children
            var o

            var __tempArray = []
            for (let i = 0; i < children.length; i++) {
                o = children[i]
                if (o._commonGeom && o._commonGeom.isDrawnData) {
                    __tempArray.push(o)
                }
            }

            for (let i = 0; i < __tempArray.length; i++) {
                o = __tempArray[i]
                if (o._commonGeom && o._commonGeom.isDrawnData) {
                    this.__objectContainer.remove(o)
                    o.destroy()
                }
            }

            this.setDirty()
        },

        refresh: function () {
            Layer.__instancesValues[this.__layerID].commonGeometries.length = 0
            this.removeAllChildren()
            var lins = Layer.__instances[this.__layerID]
            for (var i = 0; i < lins.length; i++) {
                lins[i].redraw(true)
            }
        },

        drawWalls: function (s, p) {
            if (this.isWallLayer) {
                if (!this.__wallGeometries) {
                    this.__wallGeometries = []
                }

                var catArray = []

                if (s.sampleClass.isMultiGeom) {
                    for (let i = 0; i < s.points.length; i++) {
                        for (let j = 0; j < s.points[i].length; j++) {
                            let pt = s.points[i][j]
                            let pp = this.scalable.calculatePointPositionFromLonLatAlt(pt.lon, pt.lat, pt.alt)
                            catArray.push(new THREE.Vector3(pp.x, pp.y, pp.z))
                        }

                        if (s.sampleClass === AnkaScalable.SMultiPolygon) {
                            let fp = s.points[i][0]
                            let lp = s.points[i][s.points[i].length - 1]

                            if (fp.lat !== lp.lat || fp.lon !== lp.lon || fp.alt !== lp.alt) {
                                let pt = fp
                                let pp = this.scalable.calculatePointPositionFromLonLatAlt(pt.lon, pt.lat, pt.alt)
                                catArray.push(new THREE.Vector3(pp.x, pp.y, pp.z))
                            }
                        }
                    }
                } else {
                    for (let i = 0; i < s.points.length; i++) {
                        let pt = s.points[i]
                        let pp = this.scalable.calculatePointPositionFromLonLatAlt(pt.lon, pt.lat, pt.alt)
                        catArray.push(new THREE.Vector3(pp.x, pp.y, pp.z))
                    }

                    if (s.sampleClass === AnkaScalable.SPolygon) {
                        let fp = s.points[0]
                        let lp = s.points[s.points.length - 1]

                        if (fp.lat !== lp.lat || fp.lon !== lp.lon || fp.alt !== lp.alt) {
                            let pt = fp
                            let pp = this.scalable.calculatePointPositionFromLonLatAlt(pt.lon, pt.lat, pt.alt)
                            catArray.push(new THREE.Vector3(pp.x, pp.y, pp.z))
                        }
                    }
                }

                var mat = new THREE.MeshBasicMaterial({ color: 0x00ff00, transparent: true, opacity: 0.3, side: THREE.DoubleSide, depthTest: false, depthWrite: false })
                var wg = new WallGeometry(catArray)
                var mesh = new THREE.Mesh(wg, mat)
                this.__wallGeometries.push(mesh)
                this.scalable.baseObject.addWall(mesh)
            }
        },

        findAndSetState: function () {
            if (Layer.__instancesValues[this.__layerID].currentState !== this.getState()) {
                this.setState(Layer.__instancesValues[this.__layerID].currentState)
            }
        },

        selectgeomIfExist: function () {
            console.warn('Layer::selectgeomIfExist deprecated')
        },

        clearSelection: function () {
            if (this._selectedGDH) {
                this._deselectGeom(this._selectedGDH)
            }
        },

        _deselectGeom: function (geomDataHolder) {
            if (this.__moveEnabled) {
                this.hideTransformer()
                // var geomMesh = this.__meshStorage[geomDataHolder.uniqueID]
                // geomMesh.removeEvent(SGeom.MOUSE_DOWN, this.onMouseDownOnGeom)
            } else if (this.__currentState === Layer.STATES.VERTEX_EDIT) {
                this.__drawingPlugin.stopGeometryVertexEdit()
            }

            this._selectedGDH = null
            geomDataHolder.setSelection(false)
            this.setDirty()
        },

        _selectGeom: function (geomDataHolder) {
            this.clearSelection()
            if (this.__moveEnabled) {
                let geomMesh = this.__meshStorage[geomDataHolder.uniqueID]
                var ancho = this.getTransformer()
                this._groundPositionY = this.__getHitYPos(geomMesh)
                var pos = this.posOnGround()
                if (pos) {
                    ancho.position.set(pos.x, pos.y, pos.z)
                    this.__mouseDownPos = pos
                }

                // geomMesh.addEvent(SGeom.MOUSE_DOWN, this, this.onMouseDownOnGeom)
            } else if (this.__currentState === Layer.STATES.VERTEX_EDIT) {
                let geomMesh = this.__meshStorage[geomDataHolder.uniqueID]
                this.__drawingPlugin.editGeometryVertices(geomMesh, geomDataHolder)
            }

            this._selectedGDH = geomDataHolder
            geomDataHolder.setSelection(true)

            this.setDirty()
        },

        __getHitYPos: function (m) {
            var rayCaster = this.__getRay()
            var hits = rayCaster.intersectObjects(m.children)
            if (hits.length === 0) {
                return 0
            }

            var h = hits[0].point
            return h.y
        },

        ___hideOtherAnchors: function () {
            var instances = Layer.__instances[this.__layerID]
            for (var i = 0; i < instances.length; i++) {
                var ins = instances[i]
                if (ins !== this) {
                    ins.hideTransformer()
                }
            }
        },

        _stopControlDrag: function (b) {
            var panogl = this.scalable.baseObject
            var controller = panogl.getController()
            controller.disable(b)
        },

        _updateOtherInstanceGeometries: function (geomData) {
            var keyValue = geomData.attributes[this.__keyID]
            var instances = Layer.__instances[this.__layerID]
            for (var i = 0; i < instances.length; i++) {
                var ins = instances[i]
                if (ins !== this) {
                    var nextGeom = ins.getGeomByKeyID(this.__keyID, keyValue)
                    var tiedMesh = ins.__meshStorage[nextGeom.uniqueID]
                    nextGeom.cloneFrom(geomData)
                    tiedMesh.setData(nextGeom.points, nextGeom.attributes, nextGeom.holes)
                    tiedMesh.update()
                    ins.setDirty()
                }
            }
        },

        _throwEventForAllInstances: function (geomData, eventObject) {
            var instances = Layer.__instances[this.__layerID]
            for (var i = 0; i < instances.length; i++) {
                var ins = instances[i]
                var obj = {}
                for (var str in eventObject) {
                    obj[str] = eventObject[str]
                }
                obj.layer = ins
                ins.throwEvent(obj)
            }
        },

        getTransformer: function () {
            if (!this.__anchorMesh) {
                var cubeGeom = new THREE.BoxBufferGeometry(7, 3, 7, 1, 1, 1)
                this.__anchorMesh = new THREE.Mesh(cubeGeom, new THREE.MeshBasicMaterial({ color: 0xff0000 }))

                var lineBox = new THREE.BoxBufferGeometry(1, 30, 1, 1, 1, 1)
                var lineMesh = new THREE.Mesh(lineBox, new THREE.MeshBasicMaterial({ color: 0xffff00 }))
                lineMesh.position.set(0, 15, 0)
                this.__anchorMesh.add(lineMesh)

                var slyndr = new THREE.ConeBufferGeometry(5, 20, 80)
                var m = new EventMesh(slyndr, new THREE.MeshBasicMaterial({ color: 0x00ff00 }))
                this.__anchorMesh.add(m)
                m.position.set(0, 40, 0)
                var panogl = this.scalable.baseObject
                m.setClickable(true, panogl, null, panogl.getMainCamera())
                m.addEvent(AnkaPanAPI.MeshMousePicker.MOUSE_DOWN, this, this.onAnchorDown)
            }

            if (!this.__anchorMesh.parent) {
                this.__objectContainer.add(this.__anchorMesh)
            }

            this.__anchorMesh.visible = true
            return this.__anchorMesh
        },

        __getRay: function () {
            var panogl = this.scalable.baseObject
            var cam = panogl.getMainCamera()
            var mx = panogl.globalMouseOffsetX
            var my = panogl.globalMouseOffsetY

            var cnv = panogl.getRendererDom()

            var t = new THREE.Vector2()

            t.x = (mx / cnv.width) * 2 - 1
            t.y = -(my / cnv.height) * 2 + 1

            if (!this._raycasterForMouse) {
                this._raycasterForMouse = new THREE.Raycaster()
            }
            this._raycasterForMouse.setFromCamera(t, cam)
            return this._raycasterForMouse
        },

        __getRayDirection: function () {
            var rayCaster = this.__getRay()
            return rayCaster.ray.direction
        },

        onAnchorDown: function (e) {
            this._stopControlDrag(true)
            var panogl = this.scalable.baseObject
            this.__onAnchorMouseYPos = this.getAnchorPos()

            panogl.addEvent(AnkaPanAPI.PanoGL.PRE_RENDER, this, this.onAnchorMove)
            document.addEventListener('mouseup', this.releaseAnchor)
        },

        onAnchorMove: function (e) {
            const panogl = this.scalable.baseObject
            const yPos = this.getAnchorPos()
            const sGeom = this._sGeomOnDrag
            const gdh = this.getGeomByUniqueID(sGeom.uniqueID)
            if (!gdh) {
                console.log('Geometry not found')
                return
            }

            const anchor = this.getTransformer()
            const newPosY = yPos - this.__onAnchorMouseYPos
            const realHeight = (newPosY / this.scalable.camHegInPix) * this.scalable.camHegInMet
            anchor.position.y += newPosY

            let currentAlt = 0
            const p = panogl.getCurrentPoint()
            if (p) {
                currentAlt = p.altitude - this.scalable.camHegInMet
            }

            gdh.addOffset(0, 0, realHeight, currentAlt)
            this.__onAnchorMouseYPos = yPos
        },

        releaseAnchor: function (e) {
            const panogl = this.scalable.baseObject
            this._stopControlDrag(false)
            document.removeEventListener('mouseup', this.releaseAnchor)
            panogl.removeEvent(AnkaPanAPI.PanoGL.PRE_RENDER, this.onAnchorMove)
            this.setDirty()
        },

        getAnchorPos: function () {
            var rayDirection = this.__getRayDirection()
            var y = rayDirection.y

            var anchor = this.getTransformer()
            var vector = new THREE.Vector3()
            vector.setFromMatrixPosition(anchor.matrixWorld)

            var distance = Math.sqrt((vector.x * vector.x) + (0 * 0) + (vector.z * vector.z))

            var pitch = Math.asin(y)
            var tan = Math.tan(pitch)
            var opposite = distance * tan
            return opposite
        },

        hideTransformer: function () {
            if (this.__anchorMesh) {
                this.__anchorMesh.visible = false
                if (this.__anchorMesh.parent) {
                    this.__anchorMesh.parent.remove(this.__anchorMesh)
                }
            }
        },

        getLayerInstances: function () {
            return Layer.__instances[this.__layerID]
        },

        posOnGround: function () {
            var panogl = this.scalable.baseObject
            var camera = panogl.getMainCamera()
            var canvas = panogl.getRendererDom()
            var tempVect = new THREE.Vector3()
            var normalVect = new THREE.Vector3(0, 1, 0)
            var rayCaster = new THREE.Raycaster()
            var centerVect = new THREE.Vector3(0, -this.___groundPositionY, 0)

            tempVect.x = (panogl.globalMouseOffsetX / canvas.clientWidth) * 2 - 1
            tempVect.y = -(panogl.globalMouseOffsetY / canvas.clientHeight) * 2 + 1
            rayCaster.setFromCamera(tempVect, camera)

            var dist = -(normalVect.dot(camera.position) - normalVect.dot(centerVect)) / normalVect.dot(rayCaster.ray.direction)

            var posVector = new THREE.Vector3()
            posVector.x = camera.position.x - rayCaster.ray.direction.x * dist
            posVector.y = camera.position.y - rayCaster.ray.direction.y * dist
            posVector.z = camera.position.z - rayCaster.ray.direction.z * dist

            return posVector
        },
        getGeomByKeyID: function (key, value) {
            for (let i = 0; i < this._geometries.length; i++) {
                var geom = this._geometries[i]
                if (value !== undefined && geom.attributes[key] === value) {
                    return geom
                }
            }

            var drawingGeomPool = Layer.__instancesValues[this.__layerID].drawingGeoms

            for (let i = 0; i < drawingGeomPool.length; i++) {
                if (value === drawingGeomPool[i].attributes[key]) {
                    return drawingGeomPool[i]
                }
            }

            var commonGeometries = Layer.__instancesValues[this.__layerID].commonGeometries
            for (let i = 0; i < commonGeometries.length; i++) {
                if (value === commonGeometries[i].attributes[key]) {
                    return commonGeometries[i]
                }
            }

            return undefined
        },

        onSGeomSelect: function (e) {
            var sgeom = e.currentTarget
            let geomDataHolder = this.getGeomByUniqueID(sgeom.uniqueID)
            let event = createDefaultEvent(Layer.FEATURE_CLICK, sgeom, this, geomDataHolder)
            this.throwEvent(event)
        },

        _setGeomSelect: function (sgeom) {
            var keyValue = sgeom.attributes[this.__keyID]
            var g = this.getGeomByKeyID(this.__keyID, keyValue)

            if (!g) {
                g = this.getGeomByUniqueID(sgeom.uniqueID)
            }

            var isSelected = g.isSelected()

            var instances = Layer.__instances[this.__layerID]
            for (var i = 0; i < instances.length; i++) {
                var ins = instances[i]
                ins.clearSelection()
                var clickedGeom = ins.getGeomByKeyID(this.__keyID, keyValue)
                if (clickedGeom) {
                    if (isSelected) {
                        Layer.__instancesValues[ins.__layerID].selectedKeyValue = null
                        ins.selectedKeyValue = null
                        ins._deselectGeom(clickedGeom)
                    } else {
                        Layer.__instancesValues[ins.__layerID].selectedKeyValue = keyValue
                        ins.selectedKeyValue = keyValue
                        ins._selectGeom(clickedGeom)
                    }
                    clickedGeom.notifyClick()
                    ins.setDirty()
                }
            }
        },

        getGeomByUniqueID: function (uniqueID) {
            for (let i = 0; i < this._geometries.length; i++) {
                if (uniqueID === this._geometries[i].uniqueID) {
                    return this._geometries[i]
                }
            }

            var drawingGeomPool = Layer.__instancesValues[this.__layerID].drawingGeoms
            for (let i = 0; i < drawingGeomPool.length; i++) {
                if (uniqueID === drawingGeomPool[i].uniqueID) {
                    return drawingGeomPool[i]
                }
            }

            var commonGeometries = Layer.__instancesValues[this.__layerID].commonGeometries
            for (let i = 0; i < commonGeometries.length; i++) {
                if (uniqueID === commonGeometries[i].uniqueID) {
                    return commonGeometries[i]
                }
            }

            return undefined
        },

        setDirty: function () {
            this.scalable.setDirty()
        },

        setStyleKey: function (key) {
            this._styleKey = key
        },

        getStyle: function (geometry) {
            let style = this.__style
            let key = this._styleKey
            if (typeof key === 'string') {
                let attributes = geometry.attributes
                let styleType = attributes[key]

                if (typeof style[styleType] === 'object') {
                    return style[styleType]
                } else if (style['default']) {
                    return style['default']
                }
                return style
            } else {
                return style
            }
            // temp1.attributes
        },

        // TODO remove in future versions
        selectedGeomByKeyID: function (id, b) {
            console.warn('selectedGeomByKeyID deprecated')
            var gdh = this.getGeomByKeyID(this.__keyID, id)
            if (gdh) {
                this._setGeomSelect(gdh)
                this.scalable.setDirty()
            }
            return gdh
        },

        getLayerKey: function () {
            return this.__keyID
        },

        onGeomSelect: function (e) {
            var selectedGeom
            var geomDataHolder = e.target
            var children = this.__objectContainer.children
            for (var i = 0; i < children.length; i++) {
                if (geomDataHolder.uniqueID === children[i].uniqueID) {
                    selectedGeom = geomDataHolder
                    children[i].onSelect()
                    break
                }
            }

            this.throwEvent({ type: Layer.SELECT_FEATURE, target: selectedGeom, layer: this })
        },

        onGeomDeselect: function (e) {
            var deselectedGeom
            var geomDataHolder = e.target
            var children = this.__objectContainer.children
            for (var i = 0; i < children.length; i++) {
                if (geomDataHolder.uniqueID === children[i].uniqueID) {
                    deselectedGeom = geomDataHolder
                    children[i].onDeSelect()
                }
            }
            this.throwEvent({ type: Layer.DESELECT_FEATURE, target: deselectedGeom, layer: this })
        },

        getGeomListClone: function () {
            return this._geometries.concat()
        },

        resetGeomList: function () {
            this._geometries.length = 0
        },

        removeGeomDatas: function (geoms) {
            var geomPool = Layer.__instancesValues[this.__layerID].commonGeometries
            for (var i = 0; i < geoms.length; i++) {
                var tg = geoms[i]
                tg.removeRC()
                if (!tg.hasReferance()) {
                    var index = geomPool.indexOf(tg)
                    geomPool.splice(index, 1)
                }
            }
        },

        removeConnectedChildren: function (geomDataHolder) {
            var geomMesh = this.__meshStorage[geomDataHolder.uniqueID]
            if (geomMesh) {
                this.__objectContainer.remove(geomMesh)
                geomMesh.destroy()
            }
        },

        removeAll: function () {
            var geomPool = Layer.__instancesValues[this.__layerID].commonGeometries
            for (var i = 0; i < this._geometries.length; i++) {
                var g = this._geometries[i]
                var index = geomPool.indexOf(g)
                if (index > -1) {
                    var tempG = geomPool[index]
                    tempG.removeRC()
                    if (!tempG.hasReferance()) {
                        geomPool.splice(index, 1)
                    }
                }
            }

            this._geometries.length = 0
        },

        removeAllChildren: function () {
            if (this.__wallGeometries) {
                var wall
                for (var i = 0; i < this.__wallGeometries.length; i++) {
                    wall = this.__wallGeometries[i]
                    this.scalable.baseObject.removeWall(wall)
                    wall.material.dispose()
                    wall.geometry.dispose()
                    if (wall.parent) {
                        wall.parent.remove(wall)
                    }
                }
            }

            if (this.__anchorMesh) {
                if (this.__anchorMesh.parent) {
                    this.__anchorMesh.parent.remove(this.__anchorMesh)
                }
            }

            this.__meshStorage = {}
            var children = this.__objectContainer.children
            while (children.length > 0) {
                var o = children[0]
                this.__objectContainer.remove(o)
                o.destroy()
            }

            this.setDirty()
        },

        destroy: function () {
            var index = Layer.__instances[this.__layerID].indexOf(this)
            if (index > -1) {
                Layer.__instances[this.__layerID].splice(index, 1)
            }

            for (var i = 0; i < this._geometries.length; i++) {
                this._geometries[i].removeRC()
            }
            this.removeUnusedGeometries()
        },

        removeUnusedGeometries: function () {
            var geomPool = Layer.__instancesValues[this.__layerID].commonGeometries
            for (var i = 0; i < geomPool.length; i++) {
                var tg = geomPool[i]
                if (!tg.hasReferance()) {
                    var index = geomPool.indexOf(tg)
                    geomPool.splice(index, 1)
                }
            }
        },

        setState: function (state) {
            if (this.__currentState !== state) {
                if (this.__currentState === Layer.STATES.NORMAL) {
                    // close normal
                } else if (this.__currentState === Layer.STATES.MOVE) {
                    this.__disableMove()
                } else if (this.__currentState === Layer.STATES.VERTEX_EDIT) {
                    // close Vertex
                    this.__disableVertexEdit()
                }

                this.__currentState = state

                if (this.__currentState === Layer.STATES.NORMAL) {
                } else if (this.__currentState === Layer.STATES.MOVE) {
                    this.__enableMove()
                } else if (this.__currentState === Layer.STATES.VERTEX_EDIT) {
                    this.__enableVertexEdit()
                }
            }
        },

        getOpacity: function () {
            return this.__layerOpacity
        },

        setOpacity: function (v) {
            this.__layerOpacity = v
            var sgeoms = this.__objectContainer.children

            for (var i = 0; i < sgeoms.length; i++) {
                sgeoms[i].updateStyle()
            }
            this.scalable.setDirty()
        },

        __enableVertexEdit: function () {
            var panogl = this.scalable.baseObject

            if (panogl.getDrawingPlugin) {
                this.__drawingPlugin = panogl.getDrawingPlugin()
            }

            if (!this.__drawingPlugin) {
                console.warn('Vertext edit mode requires DrawingPlugin. Using without it may harm your software')
                this.__disableVertexEdit()
                return
            }

            var gm = panogl.getGroundMaster()
            panogl.setArrowVisibility(false)
            panogl.disableGroundNav(true)
            gm.disableNavigation()
            gm.disableWallIntersection()
            this.clearSelection()
        },

        __disableVertexEdit: function () {
            var panogl = this.scalable.baseObject
            var gm = panogl.getGroundMaster()
            panogl.setArrowVisibility(true)
            panogl.disableGroundNav(false)
            gm.enableNavigation()
            this.clearSelection()
            gm.enableWallIntersection()
            this.hideTransformer()
        },

        getState: function () {
            return this.__currentState
        },

        setStatesForAllInstances: function (state) {
            Layer.__instancesValues[this.__layerID].currentState = state
            var instances = Layer.__instances[this.__layerID]
            for (var i = 0; i < instances.length; i++) {
                var ins = instances[i]
                ins.setState(state)
            }
        },

        __disableMove: function () {
            this.__moveEnabled = false
            var panogl = this.scalable.baseObject
            var gm = panogl.getGroundMaster()
            panogl.setArrowVisibility(true)
            panogl.disableGroundNav(false)
            gm.enableNavigation()
            this.clearSelection()
            this.hideTransformer()
        },

        __enableMove: function () {
            this.__moveEnabled = true
            var panogl = this.scalable.baseObject
            var gm = panogl.getGroundMaster()
            panogl.setArrowVisibility(false)
            panogl.disableGroundNav(true)
            gm.disableNavigation()
            this.clearSelection()
        },

        setLabelTextSample: function (sample) {
            this.__labelTextSample = sample
        },

        getLabelTextSample: function () {
            return this.__labelTextSample
        },

        getLabelText: function (g) {
            var ltex = this.getLabelTextSample()
            var txt = AnkaPanAPI.Utils.ParseLabelString(ltex, g)
            return txt.toString()
        },

        getGeometries: function () {
            return this._geometries
        }

    })

export { Layer }
