import {ScalablePlugin} from './ScalablePlugin'
import {Layer} from './Layer'

function AjaxLayer (name, keyID, ajaxObject, geomField, geomType, style) {
    this.geomField = geomField
    this.__ajaxObject = ajaxObject
    Layer.apply(this, [name, keyID, geomType, style])
}

AjaxLayer.prototype = Object.assign(Object.create(Layer.prototype),
    {
        constructor: AjaxLayer,

        redraw: function (refreshFromSource) {
            this.removeAllChildren()

            var cp = this.scalable.baseObject.dataParser.currentPoint
            var lat = cp.lat
            var lon = cp.lon

            var data = {
                lat: lat,
                lon: lon
            }

            if (!this.__ajaxObject.data) {
                this.__ajaxObject.data = data
            } else {
                for (var str in data) {
                    this.__ajaxObject.data[str] = data[str]
                }
            }

            if (this.__ajax) {
                this.__ajax.abort()
            }

            var t = this
            this.__ajax = $.ajax(this.__ajaxObject)
            this.__ajax.done(function (e) {
                var cloneGeoms = t.getGeomListClone()
                t.resetGeomList()
                t.__ajax = null
                var a = JSON.parse(e)
                for (var i = 0; i < a.length; i++) {
                    var d = a[i]
                    var wkt = d[t.geomField]
                    var geomData = ScalablePlugin.wktToGeomData(wkt)
                    delete d[t.geomField]
                    geomData.mergeAttributes(d)
                    t.addToList(geomData)
                }

                t.removeGeomDatas(cloneGeoms)
                // this.removeAll();

                Layer.prototype.redraw.apply(t, [refreshFromSource])
            })

            this.__ajax.fail(function (e) {
                t.__ajax = null
                console.log(e)
            })
        }

    })

export {AjaxLayer}
