import { Layer } from './Layer'

/**
*@class {SketchLayer}
* SketchLayer is designed to work synchronously with other SketchLayer instances.
* Every ScalablePlugin instance has a Sketchlayer instance attached to itself.
* new instance of SketchLayer will automatically work with other instance so that SketchLayer is not suggested to be use in normal usage.
* In order to show custom data, even for drawing tools, Layer class is suggested.
*/
function SketchLayer () {
    Layer.apply(this, arguments)
    this.isSketchLayer = true
}

let DEFAULT_LINE_STYLE = {
    lineWidth: 3,
    lineColor: 0xff00ff
}

SketchLayer.prototype = Object.assign(Object.create(Layer.prototype),
    {
        constructor: SketchLayer,

        redraw: function () {
            if (this.visible) {
                this.resetGeomList()
                var commonGeometries = Layer.__instancesValues[this.__layerID].commonGeometries
                this._geometries = commonGeometries.concat()
                Layer.prototype.redraw.apply(this, arguments)
            }

            this.scalable.setDirty()
        },

        getStyle: function (item) {
            if (item) {
                if (item.type === 'Line' || item.type === 'MultiLine') {
                    return DEFAULT_LINE_STYLE
                }
            }
            return Layer.prototype.getStyle.apply(this, [item])
        },

        refresh: function () {
            this.removeAllChildren()
            var lins = Layer.__instances[this.__layerID]
            for (var i = 0; i < lins.length; i++) {
                lins[i].redraw(true)
            }
        },

        /**
         * @method addToList
         * @param {GeomDataHolder}
         * works only synchronous. There is not way to add a geomtry for only one SketchLayer instance
         */
        // addToList: function (gdh) {
        //     var instances = this.getLayerInstances()
        //     var geom
        //     for (var i = 0; i < instances.length; i++) {
        //         geom = Layer.prototype.addToList.apply(instances[i], arguments)
        //     }

        //     if (!geom.layer) {
        //         geom.layer = this
        //     }

        //     return geom
        // },

        /**
         * @method removeFromCommonList
         * @param {GeomDataHolder}
         * works only synchronous. There is not way to add a geomtry for only one SketchLayer instance
         */
        removeFromCommonList: function (gdh) {
            var commonGeometries = Layer.__instancesValues[this.__layerID].commonGeometries
            var index = commonGeometries.indexOf(gdh)
            if (index > -1) {
                commonGeometries.splice(index, 1)
            }
        },

        removeConnectedChildren: function (gdh) {
            var instances = this.getLayerInstances()
            for (var i = 0; i < instances.length; i++) {
                Layer.prototype.removeConnectedChildren.apply(instances[i], arguments)
            }
        },

        clearAll: function () {
            var commonGeometries = Layer.__instancesValues[this.__layerID].commonGeometries
            commonGeometries.length = 0

            var instances = this.getLayerInstances()
            for (var i = 0; i < instances.length; i++) {
                instances[i].removeAllChildren()
                instances[i].scalable.setDirty()
            }
        }

    })

export { SketchLayer }
