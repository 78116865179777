
function WallGeometry (points) {
    THREE.BufferGeometry.call(this)
    this.points = points
    this.createBufferPlaneGeometry()
}

WallGeometry.prototype = Object.assign(Object.create(THREE.BufferGeometry.prototype),
    {

        constructor: WallGeometry,

        createBufferPlaneGeometry: function () {
            var vertex = []
            var uv = []
            var indices = []

            var indexCounter = 0
            var height = 5000

            var size = this.points.length - 1
            var v = Math.ceil(500 / 50)

            for (var i = 0; i < size; i++) {
                var p1 = this.points[i]
                var p2 = this.points[i + 1]

                vertex.push(p2.x, p2.y, p2.z)
                vertex.push(p2.x, p1.y + height, p2.z)

                vertex.push(p1.x, p1.y + height, p1.z)
                vertex.push(p1.x, p1.y, p1.z)

                var dx = p1.x - p2.x
                var dy = p1.y - p2.y
                var dz = p1.z - p2.z
                var dist = Math.sqrt((dx * dx) + (dy * dy) + (dz * dz))

                var u = Math.ceil(dist / 50)

                uv.push(0, 0)
                uv.push(v, 0)
                uv.push(v, u)
                uv.push(0, u)

                var inx1 = indexCounter
                var inx2 = indexCounter + 1
                var inx3 = indexCounter + 2
                var inx4 = indexCounter + 3
                indexCounter += 4

                indices.push(inx3, inx2, inx1)
                indices.push(inx1, inx4, inx3)
            }

            this.addAttribute('position', new THREE.Float32BufferAttribute(vertex, 3))
            this.addAttribute('uv', new THREE.Float32BufferAttribute(uv, 2))

            var indexArray = new Uint16Array(indices)
            this.setIndex(new THREE.BufferAttribute(indexArray, 1))

            this.setDrawRange(0, 100)
            this.attributes.position.needsUpdate = true
            this.attributes.uv.needsUpdate = true

            this.computeVertexNormals()
            return this
        }

    })

export {WallGeometry}
