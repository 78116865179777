
import { SGeom } from './SGeom'
import { EventLine } from './EventLine'

SHLine.isMultiGeom = false
function SHLine (scalable, geomdatholder) {
    SGeom.apply(this, arguments)
    this.type = 'HLine'
    this.points = []
    this.scalable = scalable
    this.softText = scalable.softText
    this.hypotenuseHook = new THREE.Object3D()
    this.degreeHook = new THREE.Object3D()
    this.neighbourHook = new THREE.Object3D()
    this.oppositeHook = new THREE.Object3D()

    this.__hooks = [this.hypotenuseHook, this.degreeHook, this.neighbourHook, this.oppositeHook]

    this.neighbourSize = 0

    if (!SHLine.__hookTexture) {
        var t = AnkaPanAPI.AssetManager.getInstance().GetAssetNoCache('img/scalable/hook.jpg')
        t.generateMipmaps = false
        t.minFilter = THREE.LinearFilter
        SHLine.__hookTexture = t
    }

    if (!SHLine.__hookGeom) {
        var geo = new THREE.Geometry()
        var vertex = new THREE.Vector3()
        geo.vertices.push(vertex)
        SHLine.__hookGeom = geo
    }

    this.hypotenusCenter = { x: 0, y: 0, z: 0 }
    this.opposideCenter = { x: 0, y: 0, z: 0 }
    this.neighbourCenter = { x: 0, y: 0, z: 0 }

    this._lineColor = new THREE.Color(1, 0, 1)
    var material = new THREE.LineBasicMaterial({
        color: this._lineColor,
        linewidth: 1,
        depthTest: true,
        transparent: true,
        depthWrite: false
    })

    this.hookGeom = SHLine.__hookGeom

    var pm = new THREE.PointsMaterial({ size: 8, sizeAttenuation: false, map: SHLine.__hookTexture, depthTest: true, color: 0x00ff00, transparent: false })
    this.hookMaterial = pm

    this.bottomHook = new THREE.Points(this.hookGeom, this.hookMaterial)
    this.add(this.bottomHook)

    // TODO top hook kullanılmadı
    this.topHook = new THREE.Points(this.hookGeom, this.hookMaterial)
    this.add(this.topHook)

    var vertexCount = 2
    this.range = vertexCount
    var geom = new THREE.BufferGeometry()
    var positions = new Float32Array(vertexCount * 3) // 3 vertices per point
    geom.addAttribute('position', new THREE.BufferAttribute(positions, 3))

    this.line = new EventLine(geom, material)
    this.line.frustumCulled = false
    this.add(this.line)

    var neighbourGeom = new THREE.BufferGeometry()
    var neighbourpositions = new Float32Array(vertexCount * 3) // 3 vertices per point
    neighbourGeom.addAttribute('position', new THREE.BufferAttribute(neighbourpositions, 3))

    var neightbouMaterial = new THREE.LineDashedMaterial({
        color: 0xffff00,
        dashSize: 3,
        gapSize: 1,
        linewidth: 2
    })

    this.neighbourEdge = new THREE.Line(neighbourGeom, neightbouMaterial)
    this.add(this.neighbourEdge)
    this.neighbourEdge.frustumCulled = false

    var oppositeGeom = new THREE.BufferGeometry()
    var oppositepositions = new Float32Array(vertexCount * 3) // 3 vertices per point
    oppositeGeom.addAttribute('position', new THREE.BufferAttribute(oppositepositions, 3))

    var dashMaterial = new THREE.LineDashedMaterial({
        color: 0xffffff,
        dashSize: 3,
        gapSize: 1,
        linewidth: 2
    })

    this.oppositeEdge = new THREE.Line(oppositeGeom, dashMaterial)
    this.add(this.oppositeEdge)
    this.oppositeEdge.frustumCulled = false

    this._raycasterForMouse = new THREE.Raycaster()
    this._mousePos = new THREE.Vector3()
}

SHLine.prototype = Object.assign(Object.create(SGeom.prototype),
    {
        constructor: SHLine,

        enable: function (panogl) {
            if (!this.enabled) {
                this.openGeom = true
                this.panogl = panogl
                var cam = panogl.getMainCamera()
                this.canDraw = true
                this.line.setClickable(true, this.panogl, null, cam)
                this.line.addEvent(SGeom.CLICK, this, this.onClick)
                this.line.addEvent(SGeom.MOUSE_DOWN, this, this.onDown)
            }
        },
        stopDraw: function () {
            this.line.frustumCulled = true
            this.line.geometry.computeBoundingSphere()
        },

        onClick: function (e) {
            e.currentTarget = this
            this.throwEvent(e)
        },

        onSelect: function () {
            this.line.material.color = new THREE.Color(0, 1, 1)
        },

        onDeSelect: function () {
            this.updateStyle()
        },

        setData: function (points, atts) {
            if (points.length > 0) {
                this.attributes = atts

                if (points.length === 1) {
                    var p = points[0]
                    this.points[0] = p
                } else {
                    this.points = [points[0], points[1]]
                }

                this.bottomHook.visible = true
                this.bottomHook.position.set(0, 0, 0)
                this.topHook.position.set(0, 120, 0)
            } else {
                this.points.length = 0
            }
        },

        getRayDirection: function () {
            var cam = this.panogl.getMainCamera()
            var mx = this.panogl.globalMouseOffsetX
            var my = this.panogl.globalMouseOffsetY

            var cnv = this.panogl.getRendererDom()
            this._mousePos.x = (mx / cnv.width) * 2 - 1
            this._mousePos.y = -(my / cnv.height) * 2 + 1
            this._raycasterForMouse.setFromCamera(this._mousePos, cam)
            var rayDirection = this._raycasterForMouse.ray.direction
            return rayDirection
        },

        setDynamicPoint: function (e) {
            if (this.points.length > 0) {
                var p2 = e
                this.points[1] = { lat: p2.lat, lon: p2.lon, alt: p2.alt }
            }
        },

        getHeightInMeter: function () {
            return this.attributes.__hightInMeter
        },

        update: function () {
            if (this.points.length < 2) return

            var geom = this.line.geometry
            var par = geom.attributes.position.array

            var p = this.points[0]
            var firstPoint = this.scalable.calculatePointPositionFromLonLatAlt(p.lon, p.lat, p.alt)
            p = this.points[1]
            var lastPoint = this.scalable.calculatePointPositionFromLonLatAlt(p.lon, p.lat, p.alt)

            var alt1 = this.points[0].alt
            var alt2 = this.points.length > 1 ? this.points[1].alt : this.points[0].alt
            if (firstPoint.y > lastPoint.y) {
                var _tempPoint = firstPoint
                firstPoint = lastPoint
                lastPoint = _tempPoint

                this.attributes.__hightInMeter = alt1 - alt2
            } else {
                this.attributes.__hightInMeter = alt2 - alt1
            }

            par[0] = 0
            par[1] = 0
            par[2] = 0

            par[3] = lastPoint.x - firstPoint.x
            par[4] = lastPoint.y - firstPoint.y
            par[5] = lastPoint.z - firstPoint.z

            this.position.set(firstPoint.x, firstPoint.y, firstPoint.z)

            geom.setDrawRange(0, this.range)
            geom.attributes.position.needsUpdate = true

            var nar = this.neighbourEdge.geometry.attributes.position.array

            nar[0] = par[0]
            nar[1] = par[1]
            nar[2] = par[2]

            nar[3] = par[3]
            nar[4] = par[1]
            nar[5] = par[5]

            this.neighbourEdge.geometry.setDrawRange(0, this.range)
            this.neighbourEdge.geometry.attributes.position.needsUpdate = true

            var dx = par[0] - par[3]
            var dz = par[2] - par[5]
            var ang = Math.atan2(dz, dx) + (Math.PI)
            var dist = Math.sqrt((dz * dz) + (dx * dx)) / 2
            this.neighbourCenter.x = dist * Math.cos(ang)
            this.neighbourCenter.z = dist * Math.sin(ang)
            this.neighbourCenter.y = 0

            this.hypotenusCenter.x = this.neighbourCenter.x
            this.hypotenusCenter.z = this.neighbourCenter.z
            this.hypotenusCenter.y = par[1] + ((par[4] - par[1]) / 2)

            var haversine = AnkaPanAPI.Utils.haversine(this.points[0].lat, this.points[1].lat, this.points[0].lon, this.points[1].lon)
            this.neighbourSize = haversine

            if (haversine < 0.02) {
                this.oppositeEdge.visible = false
                this.neighbourCenter.visible = false
            } else {
                this.neighbourCenter.visible = true
                this.oppositeEdge.visible = true
                var oar = this.oppositeEdge.geometry.attributes.position.array
                oar[0] = par[3]
                oar[1] = par[4]
                oar[2] = par[5]

                oar[3] = par[3]
                oar[4] = par[1]
                oar[5] = par[5]

                this.oppositeEdge.geometry.setDrawRange(0, this.range)
                this.oppositeEdge.geometry.attributes.position.needsUpdate = true

                this.opposideCenter.x = oar[0]
                this.opposideCenter.y = oar[1] + ((oar[4] - oar[1]) / 2)
                this.opposideCenter.z = oar[2]
            }

            this.centroid = this.position.y + (par[4] - par[1])// + (heightInPX / 2);//this.position.y + (this.opposite  / 2);
            this.setLabel()
            this.panogl.setDirty()
        },

        updateStyle: function () {
            if (this.__layer) {
                var style = this.__layer.getStyle()

                this.line.material.color = new THREE.Color(style.lineColor)
                this.line.material.opacity = style.lineOpacity * this.getOpacity()
            }
        },

        setLabel: function () {
            this.softText.removeObject3D(this.hypotenuseHook)
            this.softText.removeObject3D(this.degreeHook)
            this.softText.removeObject3D(this.neighbourHook)
            this.softText.removeObject3D(this.oppositeHook)

            if (this.points.length > 0) {
                var cam = this.panogl.getMainCamera()
                var neighbourSize = this.neighbourSize
                neighbourSize = Math.round(neighbourSize * 100) / 100
                var neighbourSizeTXT = neighbourSize + 'm'

                var slope = Math.atan(this.attributes.__hightInMeter / this.neighbourSize)
                var hypotenuse = this.attributes.__hightInMeter / Math.sin(slope)
                hypotenuse = Math.round(hypotenuse * 100) / 100
                slope = Math.round(slope * 180 / Math.PI)

                var commonGeom = this._commonGeom
                // Hypotenus /
                commonGeom.attributes['_length'] = (Math.round(this.attributes.__hightInMeter * 100) / 100) + 'm'

                var opposite = this.getLabelText()
                commonGeom.attributes['_slope'] = slope
                commonGeom.attributes['_opposite'] = opposite
                commonGeom.attributes['_adjacent'] = this.neighbourSize

                this.softText.addObject3D(this.hypotenuseHook, cam, hypotenuse + 'm')
                this.hypotenuseHook.position.set(this.hypotenusCenter.x, this.hypotenusCenter.y, this.hypotenusCenter.z)
                this.add(this.hypotenuseHook)

                if (this.oppositeEdge.visible) {
                    var oc = this.opposideCenter
                    this.oppositeHook.position.set(oc.x, oc.y * 0.9, oc.z)
                    this.add(this.oppositeHook)
                    this.softText.addObject3D(this.oppositeHook, cam, opposite)
                }

                if (this.neighbourCenter.visible) {
                    this.neighbourHook.position.set(this.neighbourCenter.x, this.neighbourCenter.y, this.neighbourCenter.z)
                    this.add(this.neighbourHook)
                    this.softText.addObject3D(this.neighbourHook, cam, neighbourSizeTXT)

                    this.degreeHook.position.set(this.position.x, this.position.y, this.position.z)
                    this.add(this.degreeHook)
                    this.softText.addObject3D(this.degreeHook, cam, slope + '°')
                }
            }
        },

        destroy: function () {
            for (var i = 0; i < this.__hooks.length; i++) {
                if (this.__hooks[i]) {
                    this.softText.removeObject3D(this.__hooks[i])

                    if (this.__hooks[i].parent) {
                        this.__hooks[i].parent.remove(this.__hooks[i])
                    }
                }
            }

            if (this.topHook) {
                this.remove(this.topHook)
            }

            if (this.bottomHook) {
                this.remove(this.bottomHook)
                this.bottomHook.material.dispose()
            }

            if (this.line) {
                if (this.line.material) {
                    this.line.material.dispose()
                }

                if (this.line.geometry) {
                    this.line.geometry.dispose()
                }

                this.line.setClickable(false)
            }

            if (this.neighbourEdge) {
                this.neighbourEdge.material.dispose()
                this.oppositeEdge.material.dispose()
                this.neighbourEdge.geometry.dispose()
                this.oppositeEdge.geometry.dispose()

                this.neighbourEdge.parent.remove(this.neighbourEdge)
                this.oppositeEdge.parent.remove(this.oppositeEdge)
            }

            if (this.parent) {
                this.parent.remove(this)
            }
        }
    })

export { SHLine }
