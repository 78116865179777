import { SGeom } from './SGeom'
import { PathGeometry } from './PathGeometry'
import { EventLine } from './EventLine'

// let boxMaterial = new PointsMaterial()

let type = 'Line'
class SLine extends SGeom {
    constructor(scalable, geomdatholder) {
        super(undefined, geomdatholder)
        this.points = []
        this.canDraw = true
        this.scalable = scalable
        this.softText = scalable.softText
        this.opacity = 0.7
        this.labelHook = new THREE.Object3D()
        this._lineColor = new THREE.Color(1, 0, 1)

        this.material = new THREE.LineMaterial({
            color: this._lineColor,
            linewidth: 5, // in pixels
            dashed: true,
            depthTest: true,
            transparent: true,
            depthWrite: false
        })

        this._vertexPointMaterial = new THREE.PointsMaterial({
            color: 0xffffff,
            size: 5,
            depthWrite: false,
            depthTest: false,
            opacity: 1,
            transparent: true,
            sizeAttenuation: false
        })

        scalable.getBasePano().addResizeMaterial(this.material)
    }

    get type() {
        return type
    }

    reset() { }

    createLine(vertices) {
        this.destroyLine()

        if (vertices.length > 0) {
            let geom = new THREE.BufferGeometry()
            geom.addAttribute('position', new THREE.BufferAttribute(new Float32Array(vertices), 3))
            let vertexPoints = new THREE.Points(geom, this._vertexPointMaterial)
            this.add(vertexPoints)
            this.vertexPoints = vertexPoints
        }

        if (vertices.length > 3) {
            var geom = new THREE.LineGeometry()
            let line = new THREE.Line2(geom, this.material)
            line.name = 'SLine_çizim'
            line.frustumCulled = false
            line.geometry.setPositions(vertices)
            line.computeLineDistances()
            this.add(line)
            this.line = line
            this._vertices = vertices
        }
    }

    enable() {
        if (!this.enabled) {
            this.openGeom = true
            this.canDraw = true
            this.enabled = true
        }
    }

    stopDraw() {
        this.openGeom = false
        this.dynamicPoint = null
        const line = this.line
        if (line) {
            line.frustumCulled = false
        }
        this.setLabel()
    }

    setLabel() {
        this.destroyLabel()

        let layer = this.__layer

        if (!layer || layer.getLabelVisibility()) {
            if (!this.openGeom) {
                var totalLength = 0
                for (var i = 0; i < this.points.length - 1; i++) {
                    var p1 = this.points[i]
                    var p2 = this.points[i + 1]
                    var length = AnkaPanAPI.Utils.haversine(p1.lat, p2.lat, p1.lon, p2.lon)
                    var p1Alt = isNaN(p1.alt) || p1.alt === undefined || p1.alt == null ? 0 : p1.alt
                    var p2Alt = isNaN(p2.alt) || p2.alt === undefined || p2.alt == null ? 0 : p2.alt

                    if (p1Alt !== p2Alt) {
                        var opposite = p1Alt - p2Alt
                        length = Math.sqrt((opposite * opposite) + (length * length))
                    }

                    totalLength += length
                }

                var c = this.getCentroid()
                let basePano = this.scalable.getBasePano()
                var cam = basePano.getMainCamera()
                this.labelHook.position.set(c.x, c.y, c.z)
                this.labelHook.name = 'hook'
                this.add(this.labelHook)
                this._commonGeom.attributes['_length'] = (Math.round(totalLength * 100) / 100) + 'm'

                if (layer && layer.isLabelOnLine) {
                    this.createGroundLabel(this.getLabelText())
                } else {
                    this.softText && this.softText.addObject3D(this.labelHook, cam, this.getLabelText())
                }
            }
        } else {
            if (this.labelHook) {
                this.softText && this.softText.removeObject3D(this.labelHook)
            }
        }
    }

    getLength() {
        var totalLength = 0
        for (var i = 0; i < this.points.length - 1; i++) {
            var p1 = this.points[i]
            var p2 = this.points[i + 1]
            var length = AnkaPanAPI.Utils.haversine(p1.lat, p2.lat, p1.lon, p2.lon)
            var p1Alt = isNaN(p1.alt) || p1.alt === undefined || p1.alt == null ? 0 : p1.alt
            var p2Alt = isNaN(p2.alt) || p2.alt === undefined || p2.alt == null ? 0 : p2.alt

            if (p1Alt !== p2Alt) {
                var opposite = p1Alt - p2Alt
                length = Math.sqrt((opposite * opposite) + (length * length))
            }

            totalLength += length
        }

        return totalLength
    }

    createGroundLabel(text) {
        var catArray = []
        for (var i = 0; i < this.points.length; i++) {
            var p = this.points[i]
            var pp = this.scalable.calculatePointPositionFromLonLatAlt(p.lon, p.lat, p.alt)
            catArray.push(new THREE.Vector3(pp.x, pp.y, pp.z))
        }

        var textureObj = AnkaPanAPI.Utils.createTextTextureForGround(text)
        var texture = textureObj.texture
        texture.generateMipmaps = false
        texture.minFilter = THREE.LinearFilter
        texture.magFilter = THREE.LinearFilter
        texture.wrapS = texture.wrapT = THREE.RepeatWrapping

        var mati = new THREE.MeshBasicMaterial({ map: texture, transparent: true, alphaTest: 0, depthWrite: false, depthTest: false })
        var ge = new PathGeometry(catArray)
        this.groundLabel = new THREE.Mesh(ge, mati)
        this.groundLabel.name = 'ground_label'
        this.add(this.groundLabel)
    }

    getCentroid() {
        let sp = this.points
        let minX = Number.MAX_SAFE_INTEGER
        let minY = Number.MAX_SAFE_INTEGER
        let minZ = Number.MAX_SAFE_INTEGER
        let maxX = Number.MIN_SAFE_INTEGER
        let maxY = Number.MIN_SAFE_INTEGER
        let maxZ = Number.MIN_SAFE_INTEGER

        for (let i = 0; i < sp.length; i++) {
            let p = sp[i]
            let pp = this.scalable.calculatePointPositionFromLonLatAlt(p.lon, p.lat, p.alt)

            if (pp.x < minX) { minX = pp.x }

            if (pp.x > maxX) { maxX = pp.x }

            if (pp.z < minZ) { minZ = pp.z }

            if (pp.z > maxZ) { maxZ = pp.z }

            if (pp.y < minY) { minY = pp.y }

            if (pp.y > maxY) { maxY = pp.y }
        }

        let x = minX + ((maxX - minX) / 2)
        let z = minZ + ((maxZ - minZ) / 2)
        let y = minY + ((maxY - minY) / 2)

        return { x: x, y: y, z: z }
    }

    onClick(e) {
        e.currentTarget = this
        this.throwEvent(e)
    }
    setDynamicPoint(p) {
        if (this.points.length > 0) {
            this.dynamicPoint = { lon: p.lon, lat: p.lat, alt: p.alt }
        }
    }

    setPoints(points) {
        this.points = points.concat()
    }

    setData(points, atts) {
        this.attributes = atts
        this.points.length = 0
        for (var i = 0; i < points.length; i++) {
            this.points.push(points[i])
        }
    }

    addPoint(lon, lat, alt) {
        this.points.push({ lon: lon, lat: lat, alt: alt })
    }

    onSelect() {
        this.line.material.color = new THREE.Color(0, 1, 1)
    }

    onDeSelect() {
        if (this.__layer) {
            this.updateStyle()
        } else {
            this.line.material.color = new THREE.Color(this._lineColor)
        }
    }

    updateStyle() {
        if (this.__layer) {
            let material = this.material
            var style = this.__layer.getStyle(this)
            material.color = new THREE.Color(style.lineColor)
            let opacity = style.lineOpacity * this.getOpacity()
            if (isNaN(opacity)) {
                opacity = 1
            }
            material.opacity = opacity
            this._vertexPointMaterial.opacity = opacity
            material.linewidth = style.lineWidth
        }
    }

    createIntersectLine() {
        this.destroyIntersectLine()

        let material = new THREE.LineBasicMaterial({
            color: new THREE.Color(1, 1, 0),
            linewidth: 1,
            depthTest: true,
            opacity: 0,
            transparent: true,
            depthWrite: false
        })

        let vertices = this._vertices
        var geom = new THREE.BufferGeometry()
        var positions = new Float32Array(vertices) // 3 vertices per point
        geom.addAttribute('position', new THREE.BufferAttribute(positions, 3))
        let line = new EventLine(geom, material)
        line.name = 'SLine_çizim'
        line.frustumCulled = false
        line.geometry.computeBoundingSphere()

        let basePano = this.scalable.getBasePano()
        var cam = basePano.getMainCamera()
        line.setClickable(true, basePano, null, cam)
        line.addEvent(SGeom.CLICK, this, this.onClick)
        line.addEvent(SGeom.MOUSE_DOWN, this, this.onDown)
        this.add(line)
        this._intersectLine = line
    }

    update() {
        this.position.set(0, 0, 0)
        let linePoints = this.points
        let vertices = []
        linePoints.forEach(point => {
            let cartesianPT = this.scalable.calculatePointPositionFromLonLatAlt(point.lon, point.lat, point.alt)
            vertices.push(cartesianPT.x, cartesianPT.y, cartesianPT.z)
        })

        if (this.dynamicPoint) {
            let dynamicPoint = this.dynamicPoint
            let dynamicCartesian = this.scalable.calculatePointPositionFromLonLatAlt(dynamicPoint.lon, dynamicPoint.lat, dynamicPoint.alt)
            vertices.push(dynamicCartesian.x, dynamicCartesian.y, dynamicCartesian.z)
        }
        this.createLine(vertices)

        this.createIntersectLine()

        this.setLabel()
    }

    disableAll() {
        if (this.parent) {
            this.parent.remove(this)
        }
        this.enabled = false
    }

    destroyIntersectLine() {
        let line = this._intersectLine
        if (line) {
            if (line.geometry) {
                line.geometry.dispose()
            }

            if (line.material) {
                line.material.dispose()
            }

            if (line.parent) {
                line.parent.remove(line)
            }

            line.setClickable(false)
            line = undefined
        }
        this._intersectLine = undefined
    }

    destroyLabel() {
        if (this.softText) {
            if (this.labelHook) {
                this.softText.removeObject3D(this.labelHook)
            }
        }
    }

    destroyLine() {
        let line = this.line
        if (line) {
            this.remove(line)
            line.geometry.dispose()
        }

        let vertexPoints = this.vertexPoints
        if (vertexPoints) {
            this.remove(vertexPoints)
            vertexPoints.geometry.dispose()
            vertexPoints.material.dispose()
        }
    }

    destroy() {
        this.disableAll()
        this.destroyLabel()
        this.destroyIntersectLine()

        if (this.material) {
            this.scalable.getBasePano().removeResizeMaterial(this.material)
            this.material.dispose()
        }

        if (this.line) {
            if (this.line.geometry) {
                this.line.geometry.dispose()
            }

            if (this.line.material) {
                this.line.material.dispose()
            }
        }

        if (this.groundLabel) {
            this.groundLabel.geometry.dispose()
            this.groundLabel.material.map.dispose()
            this.groundLabel.material.dispose()
            this.groundLabel.parent.remove(this.groundLabel)
        }

        // if (this._clickMesh) {
        //     this._clickMesh.material.dispose()
        //     this._clickMesh.geometry.dispose()
        // }
    }
}

SLine.isMultiGeom = false

export { SLine }
