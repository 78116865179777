
EventMesh.prototype = Object.create(THREE.Mesh.prototype)
var mtpt2 = Object.create(AnkaPanAPI.Base.prototype)
for (var str21232 in mtpt2) { EventMesh.prototype[str21232] = mtpt2[str21232] }
EventMesh.prototype.constructor = EventMesh

function EventMesh () {
    AnkaPanAPI.Base.apply(this, [])
    THREE.Mesh.apply(this, arguments)
    this.userData.clickPriority = 1
}

export {EventMesh}
